import { AnyAction } from 'redux';
import ActionTypes from '../constants/ActionTypes';
import { ICompanyFilter, IInvoice, INotification, IPaymentCard, IWidget } from 'types';

import { fakeCards, fakeInvoices } from 'fakes/payments';
import { fakeEssentialWidgets, fakeReviewWidgets, fakeTestimonialWidgets } from 'fakes/widgets';

interface AppState {
  paymentCards: IPaymentCard[];
  invoices: IInvoice[];
  widgets: {
    essentials: IWidget[];
    reviews: IWidget[];
    testimonials: IWidget[];
  };
  notifications: INotification[];
  searchFilter: ICompanyFilter;
}

const INIT_STATE: AppState = {
  paymentCards: fakeCards,
  invoices: fakeInvoices,
  widgets: {
    essentials: fakeEssentialWidgets,
    reviews: fakeReviewWidgets,
    testimonials: fakeTestimonialWidgets,
  },
  notifications: [],
  searchFilter: {
    rating: 0,
    verified: false,
    claimed: false,
    maxCapital: 0,
    location: [],
  },
};

const App = (state = INIT_STATE, action: AnyAction) => {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.UPDATE_COMPANY_FILTER:
      return {
        ...state,
        searchFilter: payload as ICompanyFilter,
      };
    case ActionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [payload as INotification, ...state.notifications],
      };
    case ActionTypes.CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: [],
      };
    case ActionTypes.READ_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((notification) => ({ ...notification, checked: true })),
      };
    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.id !== payload),
      };
    default:
      return state;
  }
};

export default App;
