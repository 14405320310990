import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = () => (
  <div className="loading cover-content">
    <Spinner animation="border" variant="primary" />
  </div>
);

export default Loading;
