import React, { useState, useMemo } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import Icon from 'components/Icon';

export const Notification = () => {
  const notifications = useAppSelector((store) => store.app.notifications);

  return (
    <div className="co-header-notification">
      <Icon icon="bell" />
      {notifications.length > 0 && <div className="notification-badge">{notifications.length}</div>}
    </div>
  );
};

export default Notification;
