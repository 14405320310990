import React, { FC } from 'react';
import { ElementProps } from 'components/Base/interface';
import Icon from './Icon';
import { TYPE_SIZE } from 'types';

interface BookmarkProps extends ElementProps {
  count?: number;
  size?: TYPE_SIZE;
  checked?: boolean;
}

export const Bookmark : FC<BookmarkProps> = ({ count, className = '', size = 'md', checked = true, disabled, onClick }) => {
  return (
    <div className={`co-bookmark d-flex align-items-center ${disabled && 'disabled'} ${className}`} onClick={onClick}>
      {count !== undefined && <div className="co-bookmark-number text-semibold text-medium">{count}</div>}
      <Icon className="co-bookmark-icon" icon={checked && !disabled ? 'heart' : 'outline-heart'} size={size} />
    </div>
  );
};

export default Bookmark;
