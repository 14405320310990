export const APP_PREFIX_PATH = '';
export const DASHBOARD_PREFIX_PATH = '/dashboard';
export const IS_ADMIN_APP = process.env.REACT_APP_BUILD?.trim() === 'admin';
export const ADMIN_PREFIX_PATH = IS_ADMIN_APP ? '' : '/admin';
export const REDUX_PERSIST_KEY = 'proptrust-store';

export const IS_DEMO = true;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';

export const LIMIT_FILE_SIZE = 1048576; // 1MB

export const CANDIDATES_COUNT = 10;

// export const DEFAULT_USER = {
//     id: 1,
//     name: 'Lisa Jackson',
//     thumb: '/img/profile/profile-9.webp',
//     role: USER_ROLE.Admin,
//     email: 'lisajackson@gmail.com',
// };
