import React, { ForwardRefRenderFunction, forwardRef, ReactNode } from 'react';
import Icon from '../Icon';
import type { TYPE_SIZE, VARIANT } from 'types';
import { ElementWithIconProps } from './interface';
import { OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { OverlayTriggerType } from 'react-bootstrap/OverlayTrigger';
import { Placement } from 'react-bootstrap/types';

/*
    Button Props
    - circle : 
    - size: xs, sm, md, lg, xl
    - triggger: trigger for tooltip
    - tooltipPlacement: placement of tooltip
    - loading: show spinner to indicates data fetching or action on going
    - icon: icon for button (icon name in Icon component)
    - iconPos: left, center, right
    - onClick
    
    button types (variant)
        primary, outline-primary, hover-primary, secondary, hover-secondary, hover-secondary-text, hover-secondary-text-checked, normal, gray, calendar, transparent

    ...
*/
export interface ButtonProps extends ElementWithIconProps {
  variant?: VARIANT;
  circle?: boolean;
  size?: TYPE_SIZE;
  trigger?: OverlayTriggerType | OverlayTriggerType[];
  tooltipPlacement?: Placement;
  tooltip?: ReactNode | undefined;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  value?: string | number;
  children?: ReactNode | undefined;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    variant = 'default',
    className = '',
    icon = '',
    iconPos = 'left',
    iconSize,
    size = 'lg',
    disabled = false,
    circle = false,
    onClick,
    trigger,
    tooltipPlacement,
    tooltip,
    style,
    loading,
    type = 'button',
    value,
    children,
  },
  ref
) => {
  const renderButton = () => (
    <button
      ref={ref}
      type={type}
      className={`co-button d-flex align-items-center justify-content-center ${variant} ${className} ${
        disabled || loading ? ' disabled' : ''
      }  ${circle ? ' circle' : ''} btn-size-${size}`}
      onClick={(e) => {
        if (disabled || loading) return;
        if (onClick) onClick();
      }}
      disabled={disabled}
      value={value}
      style={style}
    >
      {(circle || iconPos === 'center') && !loading && <Icon icon={icon} size={iconSize} />}

      {!circle && icon !== '' && iconPos === 'left' && <Icon icon={icon} size={iconSize} className="me-2" />}
      {loading && icon !== '' && iconPos === 'right' && <Spinner animation="border" size="sm" className="ms-2" />}

      <span className="text-nowrap">{children}</span>

      {loading && !(icon !== '' && iconPos === 'right') && <Spinner animation="border" size="sm" className="ms-2" />}

      {!circle && icon !== '' && iconPos === 'right' && <Icon icon={icon} size={iconSize} className="ms-2" />}
    </button>
  );

  return (
    <>
      {tooltip ? (
        <OverlayTrigger placement={tooltipPlacement} trigger={trigger} overlay={<Tooltip>{tooltip}</Tooltip>}>
          {renderButton()}
        </OverlayTrigger>
      ) : (
        renderButton()
      )}
    </>
  );
};

export default forwardRef(Button);
