import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, Link } from 'react-router-dom';
import { ADMIN_PREFIX_PATH } from 'configs/config';
import { useAppSelector } from 'hooks';
import Loading from 'components/Loading';
import ComponentLoader from 'components/ComponentLoader';
import SideNav from './sidenav';
import NotificationBadge from './notification';

const AdminHome = lazy(() => ComponentLoader(() => import('pages/admin/home')));
const AdminUsers = lazy(() => ComponentLoader(() => import('pages/admin/user')));
const AdminAddUser = lazy(() => ComponentLoader(() => import('pages/admin/user/add-user')));
const AdminUserDetails = lazy(() => ComponentLoader(() => import('pages/admin/user/user-details')));
const AdminPropFirms = lazy(() => ComponentLoader(() => import('pages/admin/prop-firms')));
const AdminAddPropFirm = lazy(() => ComponentLoader(() => import('pages/admin/prop-firms/add-prop-firm')));
const AdminPropFirmDetails = lazy(() => ComponentLoader(() => import('pages/admin/prop-firms/prop-firm-details')));
const AdminNotification = lazy(() => ComponentLoader(() => import('pages/admin/notification')));

export const AdminLayout = () => {
  const location = useLocation();
  const { loggedIn } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  useEffect(() => {
    scrollToTop();
  }, [location]);

  useEffect(() => {
    if (!loggedIn) navigate('/login');
  }, [loggedIn, navigate]);

  return (
    <div className="co-main-admin">
      <div className="co-content d-flex">
        <SideNav />
        <div className="co-content-admin d-flex flex-column flex-1 pb-5">
          <div className="d-flex align-items-center ms-auto mb-4 me-3">
            <Link to={`${ADMIN_PREFIX_PATH}/notification`}>
              <NotificationBadge />
            </Link>
          </div>
          <div className="container-fluid h-100">
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path={`/home`} element={<AdminHome />} />
                <Route path={`/user`}>
                  <Route index element={<AdminUsers />} />
                  <Route path="add" element={<AdminAddUser />} />
                  <Route path=":userId" element={<AdminUserDetails />} />
                </Route>
                <Route path={`/prop-firm`}>
                  <Route index element={<AdminPropFirms />} />
                  <Route path="add" element={<AdminAddPropFirm />} />
                  <Route path=":propFirmId" element={<AdminPropFirmDetails />} />
                  <Route path=":propFirmId/edit" element={<AdminAddPropFirm />} />
                </Route>                
                <Route path={`/notification`} element={<AdminNotification />} />
                {/* <Route path="*" element={<Navigate to={`${ADMIN_PREFIX_PATH}/home`} />} /> */}
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
