import { ICompanyInfo, ID, IReviewInfo, IUser, PagedData, ReviewPagedData } from 'types';
import BaseApi from './BaseApi';
import { useMutation, useQuery } from 'react-query';

class UserApi extends BaseApi {
  getUsers = (page?: number, size?: number, search?: string) => {
    return this.instance
      .get('/user', {
        params: {
          page,
          size,
          search,
        },
      })
      .then((resp) => resp.data as PagedData<IUser>);
  };

  getUser = (id: ID) => {
    return this.instance.get(`/user/${id}`).then((resp) => resp.data as IUser);
  };

  addUser = (params: { firstName: string; lastName: string; publicName: string; email: string; password: string }) => {
    return this.instance.post('/user', params).then((resp) => {
      return resp.data as IUser
    });
  };

  updateUser = (data: { id: ID; form: FormData }) => {
    return this.instance
      .patch(`/user/${data.id}`, data.form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp) => resp.data as IUser);
  };

  resetPasswordUser = (data: { id: ID; currentPassword: string; newPassword: string }) => {
    return this.instance
      .patch(`/user/${data.id}/reset-password`, {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      })
      .then((resp) => resp.data as IUser);
  };

  deleteUser = (userId: ID) => {
    return this.instance.delete(`/user/${userId}`);
  };

  getUserReviews = (id: ID, page?: number, size?: number, orderBy?: string) => {
    return this.instance
      .get(`/user/${id}/review`, {
        params: {
          page,
          size,
          orderBy,
        },
      })
      .then((resp) => resp.data as ReviewPagedData<IReviewInfo>);
  };

  getUserShortlist = (id: ID, page?: number, size?: number, orderBy?: string) => {
    return this.instance
      .get(`/user/${id}/shortlist`, {
        params: {
          page,
          size,
          orderBy,
        },
      })
      .then((resp) => resp.data as PagedData<ICompanyInfo>);
  };
}

const api = new UserApi();

export const useGetUser = (id: ID) => {
  return useQuery(['user', id], () => api.getUser(id), {
    enabled: id.toString().length > 0,
  });
};

export const useGetUsers = (page?: number, size?: number, search?: string) => {
  return useQuery(['user-list', { page, size, search }], () => api.getUsers(page, size, search), {
    keepPreviousData: true,
  });
};

export const useGetUserReviews = (id: ID | undefined, page?: number, size?: number, sort?: string) => {
  return useQuery(
    ['user-review', { id, page, size, sort }],
    () => (id ? api.getUserReviews(id, page, size, sort) : null),
    {
      keepPreviousData: true,
      enabled: !!id,
    }
  );
};

export const useGetUserShortlist = (id: ID | undefined, page?: number, size?: number, sort?: string) => {
  return useQuery(
    ['user-shortlist'],
    () => (id ? api.getUserShortlist(id, page, size, sort) : null),
    {
      keepPreviousData: true,
      enabled: !!id,
    }
  );
};

export const useAddUser = () => {
  return useMutation(api.addUser);
};

export const useUpdateUser = () => {
  return useMutation(api.updateUser);
};

export const useUserResetPassword = () => {
  return useMutation(api.resetPasswordUser);
};

export const useDeleteUser = () => {
  return useMutation(api.deleteUser);
};

export default api;
