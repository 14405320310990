import React, { ReactElement, cloneElement } from 'react';
import { ElementProps } from './interface';
import { ButtonProps } from './Button';
import { isArray } from 'lodash';

/*
    ButtonGroup Props

    current: active button's index in Button Group
    primary: style for active button (refers Button component)
    fill: expand width or fit content
    onChange: event for changing active button
*/

interface ButtonGroupProps extends ElementProps {
  current: string | number | (string | number)[];
  theme?: 'default' | 'secondary' | 'normal' | 'transparent' | 'hover-primary';
  primary?: string;
  fill?: boolean;
  children?: ReactElement<ButtonProps>[];
}

const ButtonGroup = ({
  className = '',
  disabled = false,
  current,
  fill,
  theme = 'default',
  primary = 'primary',
  onChange,
  children,
}: ButtonGroupProps) => {
  const handleClick = (value?: string | number) => {
    if (disabled) return;

    if (onChange) onChange(value);
  };

  const isActive = (value : string | number) => {
    if( isArray(current) ) {
      return current.includes(value);
    } 
    
    return current === value
  }

  return (
    <div className={`co-button-group theme-${theme} d-flex ${className} ${disabled ? 'disabled' : ''}`}>
      {children?.map((button, idx) =>
        cloneElement(button, {
          className: (isActive(button.props.value ?? idx) ? primary : theme) + (fill ?? ' flex-1') + ' ' + button.props.className,
          onClick: () => handleClick(button.props.value ?? idx),
          key: `co-button-group-${idx}`,
        })
      )}
    </div>
  );
};

export default ButtonGroup;
