import React, { ReactNode, FC } from 'react';
import { ElementProps } from './interface';
import { TYPE_SIZE } from 'types';

interface RadioBoxProps extends ElementProps {
  title?: string;
  titleClassName?: string;
  value: string | number | boolean;
  current?: string | number | boolean;
  bordered?: boolean;
  rtl?: boolean;
  height?: TYPE_SIZE;
  render?: () => ReactNode;
}

const RadioBox: FC<RadioBoxProps> = ({
  className = '',
  style,
  value,
  current,
  title = '',
  titleClassName = '',
  height = 'md',
  disabled = false,
  bordered,
  render,
  rtl,
  onChange,
}) => {
  return (
    <div
      className={`co-radiobox co-radiobox-${height} d-flex align-items-center ${className} ${
        disabled ? 'disabled' : ''
      } ${bordered ? 'bordered' : ''} ${value === current ? 'checked' : ''}`}
      style={style}
      onClick={() => {
        if (disabled) return;
        if (onChange) onChange(value);
      }}
    >
      <div className={`co-radiobox-box flex-0 ${rtl ? 'order-2 ms-auto' : ''}`}></div>
      {render ? (
        <div className={`${rtl ? 'me-2' : 'ms-2'}`}>{render()}</div>
      ) : (
        title !== '' && (
          <div className={`co-radiobox-text text-nowrap ${titleClassName} ${rtl ? 'me-2' : 'ms-2'}`}>{title}</div>
        )
      )}
    </div>
  );
};

export default RadioBox;
