export type TYPE_SIZE = 'auto' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined;
export type ID = string | number;
export type ALERT_TYPE = 'success' | 'danger' | 'warning' | 'info';

export enum USER_ROLE {
  USER = 'user',
  COMPANY = 'company',
  SUPERADMIN = 'superAdmin',
}

export enum CompanyStatus {
  UNKNOWN = 'unknown',
  CLAIMED = 'claimed',
  VERIFIED = 'verified',
}

export enum InteractionTypes {
  LIKE = 'like',
  DISLIKE = 'dislike',
  FOUND_USEFUL = 'foundUseful',
}

export enum FileTypes {
  PHOTO = 'photo',
  VIDEO = 'video',
  REVIEW = 'review',
  REVIEW_REASON = 'reason',
}

export enum ReportTypes {
  REVIEW = 'review',
  PACKAGE = 'package',
  MEDIA = 'media',
  COMMENT = 'comment',
}

export enum NotificationTypes {
  INDIVIDUAL = 'individual',
  PLATFORM = 'platform',
}

export enum AnalyticTypes {
  PROFILE_VIEW = 'profileView',
  VISIT_SITE = 'visitWebsite',
}

export const variants = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  NORMAL: 'normal',
  GRAY: 'gray',
  DANGER: 'danger',
  OUTLINEPRIMARY: 'outline-primary',
  HOVEROUTLINEPRIMARY: 'hover-outline-primary',
  HOVERPRIMARY: 'hover-primary',
  HOVERSECONDARY: 'hover-secondary',
  HOVERSECONDARYTEXT: 'hover-secondary-text',
  HOVERSECONDARYTEXTCHECKED: 'hover-secondary-text-checked',
  CALENDAR: 'calendar',
  TRANSPARENT: 'transparent',
  LINK: 'link',
  HOVERLINK: 'hover-link',
  LINKDANGER: 'link-danger',
  LINKSUCCESS: 'link-success',
} as const;

export enum REVIEW_SORT {
  RATING = 'rating',
  LATEST = 'latest',
}

export enum COMPANY_SORT {
  LATEST = 'latest',
  RATING = 'rating',
  REVIEWS = 'reviews',
}

export type VARIANT = (typeof variants)[keyof typeof variants];

export interface MapString {
  [key: string]: string;
}
export interface MapCheck {
  [key: string]: {
    title?: string;
    checked: boolean;
  };
}

export interface ICompanyInfo {
  id: ID;
  slug?: string;
  email: string;
  logoUrl: string | null;
  iconUrl: string | null;
  name: string;
  averageRating: number;
  reviewsNum: number;
  aboutContent: string;
  website: string;
  foundedDate?: Date;
  phoneNumber?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  packages?: IPackage[];
  status: CompanyStatus;
  supportEmail?: string;
  linkedinUrl?: string;
  facebookUrl?: string;
  discordUrl?: string;
  telegramUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
  youtubeUrl?: string;
  bannerUrl?: string;
  favorite?: boolean;
  isActive?: boolean;
}

export interface IInteraction {
  type: InteractionTypes;
  user: IUser;
  review: IReviewInfo
}

export interface IReviewInfo {
  id: ID;
  company: ICompanyInfo;
  user: IUser;
  rating: number;
  title: string;
  content: string;
  suggestion?: string;
  medias?: IMedia[];
  reasons?: string[];
  interactions: IInteraction[];
  reports?: IReport[];
  reported?: boolean;
  reply?: IReviewReply;
  useful?: boolean;  
  createdAt: Date;
  updatedAt?: Date;
}

export interface IReviewReply {
  id: ID;
  content: string;
  interactions: IInteraction[];
  medias?: IMedia[];
  review?: IReviewInfo;
  like?: boolean;
  dislike?: boolean;
  likeNum?: number;
  dislikeNum?: number;
  createdAt: Date;
  updatedAt?: Date;
}

export interface IFile {
  data: string;
  url?: string;
  file: File;
}

export interface IFAQ {
  id: ID;
  title: string;
  content: string;
  companyId: string;
  company?: ICompanyInfo;
}

export interface IPlan {
  id: ID;
  name: string;
  subPlans: IPlan[];
  numPhases: number;
  parent?: IPlan;
  // packages?: IPackage[];
}

export interface IPhase {
  id?: ID,
  order: number,
  tradingPeriod: number,
  minimumTradingDays: number,
  profitSplit: number,
  maxProfitSplit: number,
  maxDailyLoss: number,
  maxTotalLoss: number,
  target: number,
  accountLeverage: number,
  isRefundable: boolean
}

export interface IPackage {
  id: ID;
  capital: number;
  purchasePrice: number;
  phases: IPhase[];
  plan?: IPlan
}

export interface IMedia {
  id: ID;
  name: string;
  location: string;
  type: FileTypes;
  review?: IReviewInfo;
  company?: ICompanyInfo;
}
export interface IVideo {
  id: ID;
  url: string;
}

export interface IComment {
  id: ID;
  user: IUser;
  comment: string;
  useful?: boolean;
  report?: string;
  report_at?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface IPaymentCard {
  id: ID;
  type: 'mastercard' | 'visa';
  cardNum: string;
  valid: {
    month: number;
    year: number;
  };
  cvv: number;
  holder: string;
}

export interface IInvoice {
  id: ID;
  type: string;
  due_date: Date;
  status: 'Paid' | 'Due' | 'Cancelled';
  amount: number;
  createdAt: Date;
}

export interface IReport {
  id: ID;
  type: ReportTypes;
  content: string;
  review?: IReviewInfo;
  media?: IMedia;
  package?: IPackage;
  comment?: IComment
}

export interface IWidget {
  id: ID;
  name: string;
  template: string;
  isPopular?: boolean;
  isLocked?: boolean;
}

export enum NOTIFICATION_TYPE {
  ADD_COMPNAY = 'add-company',
  REMOVE_COMPANY = 'remove-company',
  ADD_USER = 'add-user',
  REMOVE_USER = 'remove-user',
  ADD_REVIEW = 'add-review',
  REMOVE_REVIEW = 'remove-review',
  ADD_REPORT = 'add-report',
  REMOVE_REPORT = 'remove-report',
  ADD_REPLY = 'add-reply',
  REMOVE_REPLY = 'remove-reply'
}

export interface INotification {
  id: ID;
  type: NOTIFICATION_TYPE;
  user: IUser | null;
  company: ICompanyInfo | null;
  review: IReviewInfo | null;
  reply: IReviewReply | null;
  report: IReport | null;
  createdAt: Date;
  checked?: boolean;
}

export interface IUser {
  id: ID;
  firstName: string;
  lastName: string;
  publicName?: string;
  profilePictureUrl?: string;
  email: string;
  reviewsNum?: number;
  averageRating?: number;
  foundUsefulNum?: number;
  country?: string;
  role: USER_ROLE;
  lastLogOut?: Date;
  createdAt: Date;
  updatedAt: Date;
}

interface PageMetaDto {
  page: number;
  size: number;
  count: number;
  pages: number;
}

// export type PagedData<Key extends string, Type> = BasePage & Record<Key, Type[]>;

export interface PagedData<Type> {
  meta: PageMetaDto;
  data: Type[];
}

export interface ReviewPagedData<Type> {
  info: {
    stars: number[],
    averageRating: number,
  };
  meta: PageMetaDto;
  data: Type[];
}

export interface ICompanyFilter {
  rating: number;
  verified: boolean;
  claimed: boolean;
  maxCapital: number;
  location: string[];
}

export interface IReviewFilter {
  ratings: number[];
  replies: boolean;
  daysToView: number;
}

export interface IDashboardResponse {
  reviewNum?: number,     // for user and company
  favoriteNum?: number,   // for user
  userfulNum?: number,    // for user
  profileViews?: number,  // for company,
  throughs?: number,      // for company,
  averageRating?: number, // for company,
  userNum?: number;       // for admin
  companyNum?: number;    // for admin
}

export enum PropFirmCSVFields {
  NAME = "Name",
  SLUG = "Slug",
  LOGO = "Logo",
  SYMBOL = "Symbol",
  WEBSITE = "Website",
  DISCORD = "Discord",
  TWITTER = "Twitter",
  INSTAGRAM = "Instagram",
  FACEBOOK = "Facebook",
  VIDEOS = "Videos",
  COUNTRY = "Country",
  EMAIL = "Email",
  FOUNDED_YEAR = "Founded Year",
  SHORT_BIO = "Short Bio",
  ABOUT = "About",
  SHOW = "Show",
}

export enum PlanCSVFields {
  NAME = "Name",
  ACCOUNT_SIZE = "Account size",
  PROFIT_TARGET_1 = "Profit Target - Phase 1",
  PROFIT_TARGET_2 = "Profit Target - Phase 2",
  PROFIT_TARGET_3 = "Profit Target - Phase 3",
  PROFIT_TARGET_LIVE = "Profit Target - Live Phase",
  TRADING_PERIOD_1 = "Trading Period - Phase 1",
  TRADING_PERIOD_2 = "Trading Period - Phase 2",
  TRADING_PERIOD_3 = "Trading Period - Phase 3",
  MIN_TRADING_DAYS_1 = "Minimum Trading Days - Phase 1",
  MIN_TRADING_DAYS_2 = "Minimum Trading Days - Phase 2",
  MIN_TRADING_DAYS_3 = "Minimum Trading Days - Phase 3",
  MIN_TRADING_DAYS_LIVE = "Minimum Trading Days - Live Phase",
  MAX_DAILY_LOSS_1 = "Maximum Daily Loss - Phase 1",
  MAX_DAILY_LOSS_2 = "Maximum Daily Loss - Phase 2",
  MAX_DAILY_LOSS_3 = "Maximum Daily Loss - Phase 3",
  MAX_DAILY_LOSS_LIVE = "Maximum Daily Loss - Live Phase",
  MAX_LOSS_1 = "Maximum Loss - Phase 1",
  MAX_LOSS_2 = "Maximum Loss - Phase 2",
  MAX_LOSS_3 = "Maximum Loss - Phase 3",
  MAX_LOSS_LIVE = "Maximum Loss - Live Phase",
  MAX_TRADING_LEVERAGE = "Maximum Trading Leverage",
  PURCHASE_PRICE = "Purchase Price New",
  REFUNDABLE_FEE = "Refundable Fee",
  PROPFIRM = "Prop Firm Associated",
  STEPS = "Steps",
  STOP_LOSS_REQUIRED = "Stop Loss Required",
  ACCOUNT_TYPE = "Name of Account Type",
  FAVORITED = "Favorited",
  SHOW = "Show",
}
