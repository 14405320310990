import React, { useState, useRef, useEffect, TextareaHTMLAttributes, ChangeEventHandler, ReactNode } from 'react';
import { ElementProps } from './interface';
import _ from 'lodash';

interface TextAreaProps extends ElementProps, TextareaHTMLAttributes<HTMLTextAreaElement> {
  value?: string;
  label?: string | ReactNode | ReactNode[];
  fieldClassName?: string;
  error?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onChangeValue?: (val: string) => void;
  onClick?: () => void;
}

const TextArea = function ({
  value = '',
  label,
  required = false,
  className = '',
  fieldClassName = '',
  error,
  onChange,
  onChangeValue,
  ...others
}: TextAreaProps) {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = value;
  }, [value]);

  return (
    <div className={'input ' + className}>
      {label && (
        <div className="co-label">
          {label}
          {required && <span className="fc-red">*</span>}
        </div>
      )}
      <div className="co-field-wrapper h-100">
        <textarea
          ref={inputRef}
          className={`co-field-textarea ${fieldClassName}`}
          onChange={(event) => {
            if (onChange) onChange(event);
            if (onChangeValue) onChangeValue(event.target.value);
          }}
          {...others}
        />
      </div>
      {error && <div className="co-field-error">{_.capitalize(error)}</div>}
    </div>
  );
};

export default TextArea;
