import { combineReducers } from 'redux';
import App from './App';
import Auth from './auth';

const reducers = combineReducers({
  app: App,
  auth: Auth,
});

export default reducers;
