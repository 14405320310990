import React, { EventHandler, FC } from 'react';
import { TYPE_SIZE } from 'types';
import { ElementProps } from './interface';

interface ToggleProps extends ElementProps {
  value: boolean;
  title?: string;
  titleLeft?: string;
  titleRight?: string;
  textAlign?: 'left' | 'right';
  size?: TYPE_SIZE;
  both?: boolean;
}

const Toggle: FC<ToggleProps> = ({
  className = '',
  value = false,
  title = '',
  titleLeft = '',
  titleRight = '',
  textAlign = 'right',
  disabled = false,
  both = false,
  size = 'lg',
  onChange,
}) => {
  return (
    <div
      className={`co-toggle d-flex align-items-center ${className} co-toggle-${size} ${disabled ? 'disabled' : ''} ${
        value ? 'checked' : ''
      } ${both ? 'both' : ''}`}
    >
      {(both || textAlign === 'left') && (
        <div className="co-toggle-text text-nowrap me-2">{both ? titleLeft : title}</div>
      )}
      <div
        className={`co-toggle-wrapper d-flex align-items-center`}
        onClick={() => {
          if (disabled) return;
          if (onChange) onChange(!value);
        }}
      >
        <div className="co-toggle-icon"></div>
      </div>
      {(both || textAlign === 'right') && (
        <div className="co-toggle-text text-nowrap ms-2">{both ? titleRight : title}</div>
      )}
    </div>
  );
};

export default Toggle;
