import React, { ReactNode, FC } from 'react';
import { ElementProps } from './interface';
import { VARIANT, TYPE_SIZE } from 'types';

interface CheckBoxProps extends ElementProps {
  variant?: VARIANT;
  checked: boolean;
  title?: string;
  titleClassName?: string;
  bordered?: boolean;
  circle?: boolean;
  height?: TYPE_SIZE;
  render?: () => ReactNode;
}

const CheckBox: FC<CheckBoxProps> = ({
  className = '',
  variant = 'primary',
  checked = false,
  title = '',
  titleClassName = '',
  height = 'md',
  disabled = false,
  circle = false,
  render,
  bordered,
  onChange,
}) => {
  return (
    <div
      className={`co-checkbox co-checkbox-${height} ${variant} d-flex align-items-center ${className} ${
        disabled && 'disabled'
      } ${bordered && 'bordered'} ${checked && 'checked'} ${circle && 'circle'}`}
      onClick={() => {
        if (disabled) return;
        if (onChange) onChange(!checked);
      }}
    >
      <div className={`co-checkbox-box flex-0`} />
      {render
        ? render()
        : title !== '' && <div className={`co-checkbox-text text-nowrap ms-2 ${titleClassName}`}>{title}</div>}
    </div>
  );
};

export default CheckBox;
