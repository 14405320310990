import { defineAction } from 'redux-define';

export default {
  // SIGNUP
  AUTH_LOGIN: defineAction('AUTH_LOGIN', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_SIGNUP: defineAction('AUTH_SIGNUP', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_LOGOUT: defineAction('AUTH_LOGOUT', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_COMPANY_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_COMPANY_FILTER: 'UPDATE_USER_FILTER',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
};
