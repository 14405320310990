import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { DASHBOARD_PREFIX_PATH } from 'configs/config';
import { useAppDispatch, useAppSelector } from 'hooks';
import ActionTypes from 'store/constants/ActionTypes';
import Icon from 'components/Icon';

const menus = [
  {
    url: '/home',
    caption: 'Home',
    icon: 'menu-home',
  },
  {
    url: '/profile',
    caption: 'Profile',
    icon: 'menu-profile',
  },
  {
    url: '/faq',
    caption: 'FAQs',
    icon: 'menu-faq',
  },
  // {
  //   url: '/payment',
  //   caption: 'Payments',
  //   icon: 'menu-payment',
  // },
  {
    url: '/review',
    caption: 'Reviews',
    icon: 'menu-review',
  },
  // {
  //   url: '/widget',
  //   caption: 'Widget',
  //   icon: 'menu-widget',
  // },
  {
    url: '/package',
    caption: 'Packages',
    icon: 'menu-package',
  },
  {
    url: '/media',
    caption: 'Media',
    icon: 'menu-media',
  },
  {
    url: '/report',
    caption: 'Reports',
    icon: 'flag',
  },
  {
    url: '/setting',
    caption: 'Settings',
    icon: 'menu-setting',
  },
];

export const SideNav = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isCurrent = (url: string) => {
    return location.pathname.startsWith(url);
  };

  return (
    <div className="co-left-panel">
      {menus.map((menu, idx) => (
        <Link
          to={`${DASHBOARD_PREFIX_PATH}${menu.url}`}
          className={`sidebar-navbar-link d-flex align-items-center ${
            isCurrent(DASHBOARD_PREFIX_PATH + menu.url) ? 'current' : ''
          }`}
          key={idx}
          title={menu.caption}
        >
          <div className="link-icon d-flex align-items-center justify-content-center">
            <Icon icon={menu.icon} />
          </div>
          <div className="link-label ms-3">{menu.caption}</div>
        </Link>
      ))}
    </div>
  );
};

export default SideNav;
