import React, { FC, useMemo } from 'react';
import { ElementProps } from 'components/Base/interface';
import imgDefaultAvatar from 'assets/images/avatar.png';
import { shortName } from 'helpers/utils';
import { TYPE_SIZE } from 'types';

interface AvatarProps extends ElementProps {
  src?: string | null;
  name?: string;
  color?: string;
  size?: TYPE_SIZE;
}

export const Avatar : FC<AvatarProps> = ({ src, name, color, className = '', size = 'md' }) => {
  const shortedName = useMemo(() => {
    if (name) return shortName(name);
    return '';
  }, [name]);

  return (
    <>
      {src && src.length > 0 ? (
        <img src={src || imgDefaultAvatar} className={`co-avatar co-avatar-${size} ${className}`} alt={name} />
      ) : (
        <div className={`co-avatar no-avatar co-avatar-${size} ${color} ${className}`}>{shortedName}</div>
      )}
    </>
  );
};

export default Avatar;
