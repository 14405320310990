import React from 'react';
import { ReactComponent as Search } from 'assets/images/icon-search.svg';
import { ReactComponent as Heart } from 'assets/images/icon-heart.svg';
import { ReactComponent as HeartOutline } from 'assets/images/icon-heart-outline.svg';
import { ReactComponent as ShowPassword } from 'assets/images/icon-pass.svg';
import { ReactComponent as HidePassword } from 'assets/images/icon-hide-pass.svg';
import { ReactComponent as ChevronLeft } from 'assets/images/icon-chevron-left.svg';
import { ReactComponent as ArrowLeft } from 'assets/images/icon-arrow-left.svg';
import { ReactComponent as Dropdown } from 'assets/images/icon-dropdown.svg';
import { ReactComponent as Dropdown2 } from 'assets/images/icon-dropdown2.svg';
import { ReactComponent as Dropdown3 } from 'assets/images/icon-dropdown3.svg';
import { ReactComponent as Dropdown4 } from 'assets/images/icon-dropdown4.svg';
import { ReactComponent as Star } from 'assets/images/icon-star.svg';
import { ReactComponent as Star2 } from 'assets/images/icon-star2.svg';
import { ReactComponent as StarOutline } from 'assets/images/icon-star-outline.svg';
import { ReactComponent as Verify } from 'assets/images/icon-verify.svg';
import { ReactComponent as Package } from 'assets/images/icon-package.svg';
import { ReactComponent as Calendar } from 'assets/images/icon-calendar.svg';
import { ReactComponent as Location } from 'assets/images/icon-location.svg';
import { ReactComponent as Eye } from 'assets/images/icon-eye.svg';
import { ReactComponent as Global } from 'assets/images/icon-global.svg';
import { ReactComponent as Building } from 'assets/images/icon-building.svg';
import { ReactComponent as Prev } from 'assets/images/icon-prev.svg';
import { ReactComponent as First } from 'assets/images/icon-first.svg';
import { ReactComponent as Dot } from 'assets/images/icon-dot.svg';
import { ReactComponent as Linkedin } from 'assets/images/icon-linkedin.svg';
import { ReactComponent as Discord } from 'assets/images/icon-discord.svg';
import { ReactComponent as Telegram } from 'assets/images/icon-telegram.svg';
import { ReactComponent as Instagram } from 'assets/images/icon-instagram.svg';
import { ReactComponent as Twitter } from 'assets/images/icon-twitter.svg';
import { ReactComponent as Youtube } from 'assets/images/icon-youtube.svg';
import { ReactComponent as Like } from 'assets/images/icon-like.svg';
import { ReactComponent as LikeOutline } from 'assets/images/icon-like-outline.svg';
import { ReactComponent as Flag } from 'assets/images/icon-flag.svg';
import { ReactComponent as Back } from 'assets/images/icon-back.svg';
import { ReactComponent as Reply } from 'assets/images/icon-reply.svg';
import { ReactComponent as ReplyOutline } from 'assets/images/icon-reply-outline.svg';
import { ReactComponent as Filter } from 'assets/images/icon-filter.svg';
import { ReactComponent as Plus } from 'assets/images/icon-plus.svg';
import { ReactComponent as Close } from 'assets/images/icon-close.svg';
import { ReactComponent as CircleClose } from 'assets/images/icon-circle-close.svg';
import { ReactComponent as Pin } from 'assets/images/icon-pin.svg';
import { ReactComponent as Phone } from 'assets/images/icon-phone.svg';
import { ReactComponent as ShortEmail } from 'assets/images/icon-shortemail.svg';
import { ReactComponent as Info } from 'assets/images/icon-info.svg';
import { ReactComponent as Edit } from 'assets/images/icon-edit.svg';
import { ReactComponent as Trash } from 'assets/images/icon-trash.svg';
import { ReactComponent as MenuHome } from 'assets/images/menu/home.svg';
import { ReactComponent as MenuProfile } from 'assets/images/menu/profile.svg';
import { ReactComponent as MenuFAQ } from 'assets/images/menu/faq.svg';
import { ReactComponent as MenuPayment } from 'assets/images/menu/payment.svg';
import { ReactComponent as MenuReview } from 'assets/images/menu/review.svg';
import { ReactComponent as MenuWidget } from 'assets/images/menu/widget.svg';
import { ReactComponent as MenuPackage } from 'assets/images/menu/package.svg';
import { ReactComponent as MenuMedia } from 'assets/images/menu/media.svg';
import { ReactComponent as MenuSetting } from 'assets/images/menu/setting.svg';
import { ReactComponent as MenuUser } from 'assets/images/menu/user.svg';
import { ReactComponent as MenuPropFirm } from 'assets/images/menu/propfirm.svg';
import { ReactComponent as MenuArticle } from 'assets/images/menu/article.svg';
import { ReactComponent as MenuAuthor } from 'assets/images/menu/author.svg';
import { ReactComponent as MasterCard } from 'assets/images/icon-payment-master.svg';
import { ReactComponent as Visa } from 'assets/images/icon-payment-visa.svg';
import { ReactComponent as Lock } from 'assets/images/icon-lock.svg';
import { ReactComponent as Bell } from 'assets/images/icon-bell.svg';
import { ReactComponent as Users } from 'assets/images/icon-users.svg';
import { ReactComponent as Firms } from 'assets/images/icon-firms.svg';
import { ReactComponent as NotificationSuccess } from 'assets/images/icon-notification-success.svg';
import { ReactComponent as NotificationWarning } from 'assets/images/icon-notification-warning.svg';
import { ReactComponent as NotificationDanger } from 'assets/images/icon-notification-danger.svg';
import { ReactComponent as NotificationInfo } from 'assets/images/icon-notification-info.svg';
import { ReactComponent as FolderUpload } from 'assets/images/icon-folder-upload.svg';

import type { TYPE_SIZE } from 'types';

interface IconProps {
  width?: number;
  height?: number;
  icon?: string;
  size?: TYPE_SIZE;
  className?: string;
}

const icons = [
  {
    name: 'search',
    icon: (props: IconProps) => <Search {...props} method="stroke" />,
  },
  {
    name: 'heart',
    icon: (props: IconProps) => <Heart {...props} method="fill" />,
  },
  {
    name: 'outline-heart',
    icon: (props: IconProps) => <HeartOutline {...props} method="stroke" />,
  },
  {
    name: 'show-pass',
    icon: (props: IconProps) => <ShowPassword {...props} method="fill" />,
  },
  {
    name: 'hide-pass',
    icon: (props: IconProps) => <HidePassword {...props} method="fill" />,
  },
  {
    name: 'chevron-left',
    icon: (props: IconProps) => <ChevronLeft {...props} method="fill" />,
  },
  {
    name: 'chevron-right',
    icon: (props: IconProps) => <ChevronLeft {...props} method="fill" style={{ transform: 'rotate(180deg)' }} />,
  },
  {
    name: 'chevron-up',
    icon: (props: IconProps) => <ChevronLeft {...props} method="fill" style={{ transform: 'rotate(-90deg)' }} />,
  },
  {
    name: 'chevron-down',
    icon: (props: IconProps) => <ChevronLeft {...props} method="fill" style={{ transform: 'rotate(90deg)' }} />,
  },
  {
    name: 'arrow-left',
    icon: (props: IconProps) => <ArrowLeft {...props} method="stroke" />,
  },
  {
    name: 'arrow-right',
    icon: (props: IconProps) => <ArrowLeft {...props} method="stroke" style={{ transform: 'rotate(180deg)' }} />,
  },
  {
    name: 'arrow-up',
    icon: (props: IconProps) => <ArrowLeft {...props} method="stroke" style={{ transform: 'rotate(-90deg)' }} />,
  },
  {
    name: 'arrow-down',
    icon: (props: IconProps) => <ArrowLeft {...props} method="stroke" style={{ transform: 'rotate(90deg)' }} />,
  },
  {
    name: 'dropdown',
    icon: (props: IconProps) => <Dropdown {...props} method="fill" />,
  },
  {
    name: 'dropdown2',
    icon: (props: IconProps) => <Dropdown2 {...props} method="fill" />,
  },
  {
    name: 'dropdown3',
    icon: (props: IconProps) => <Dropdown3 {...props} method="fill" />,
  },
  {
    name: 'dropdown4',
    icon: (props: IconProps) => <Dropdown4 {...props} method="fill" />,
  },
  {
    name: 'prev',
    icon: (props: IconProps) => <Prev {...props} method="fill" />,
  },
  {
    name: 'next',
    icon: (props: IconProps) => <Prev {...props} method="fill" style={{ transform: 'rotate(180deg)' }} />,
  },
  {
    name: 'first',
    icon: (props: IconProps) => <First {...props} method="stroke" />,
  },
  {
    name: 'last',
    icon: (props: IconProps) => <First {...props} method="stroke" style={{ transform: 'rotate(180deg)' }} />,
  },
  {
    name: 'star',
    icon: (props: IconProps) => <Star {...props} method="fill" />,
  },
  {
    name: 'star-2',
    icon: (props: IconProps) => <Star2 {...props} method="fill" />,
  },
  {
    name: 'outline-star',
    icon: (props: IconProps) => <StarOutline {...props} method="fill" />,
  },
  {
    name: 'verify',
    icon: (props: IconProps) => <Verify {...props} method="fill" />,
  },
  {
    name: 'package',
    icon: (props: IconProps) => <Package {...props} method="fill" />,
  },
  {
    name: 'calendar',
    icon: (props: IconProps) => <Calendar {...props} method="fill" />,
  },
  {
    name: 'location',
    icon: (props: IconProps) => <Location {...props} method="fill" />,
  },
  {
    name: 'eye',
    icon: (props: IconProps) => <Eye {...props} method="fill" />,
  },
  {
    name: 'global',
    icon: (props: IconProps) => <Global {...props} method="fill" />,
  },
  {
    name: 'building',
    icon: (props: IconProps) => <Building {...props} method="stroke" />,
  },
  {
    name: 'dot',
    icon: (props: IconProps) => <Dot {...props} method="fill" />,
  },
  {
    name: 'linkedin',
    icon: (props: IconProps) => <Linkedin {...props} method="fill" />,
  },
  {
    name: 'discord',
    icon: (props: IconProps) => <Discord {...props} method="fill" />,
  },
  {
    name: 'telegram',
    icon: (props: IconProps) => <Telegram {...props} method="fill" />,
  },
  {
    name: 'instagram',
    icon: (props: IconProps) => <Instagram {...props} method="fill" />,
  },
  {
    name: 'twitter',
    icon: (props: IconProps) => <Twitter {...props} method="fill" />,
  },
  {
    name: 'youtube',
    icon: (props: IconProps) => <Youtube {...props} method="fill" />,
  },
  {
    name: 'like',
    icon: (props: IconProps) => <Like {...props} method="fill" />,
  },
  {
    name: 'dislike',
    icon: (props: IconProps) => <Like {...props} method="fill" style={{ transform: 'rotateX(180deg)' }} />,
  },
  {
    name: 'outline-like',
    icon: (props: IconProps) => <LikeOutline {...props} method="stroke" />,
  },
  {
    name: 'flag',
    icon: (props: IconProps) => <Flag {...props} method="fill" />,
  },
  {
    name: 'back',
    icon: (props: IconProps) => <Back {...props} method="stroke" />,
  },
  {
    name: 'reply',
    icon: (props: IconProps) => <Reply {...props} method="fill" />,
  },
  {
    name: 'outline-reply',
    icon: (props: IconProps) => <ReplyOutline {...props} method="fill" />,
  },
  {
    name: 'filter',
    icon: (props: IconProps) => <Filter {...props} method="stroke" />,
  },
  {
    name: 'plus',
    icon: (props: IconProps) => <Plus {...props} method="none" />,
  },
  {
    name: 'close',
    icon: (props: IconProps) => <Close {...props} method="stroke" />,
  },
  {
    name: 'circle-close',
    icon: (props: IconProps) => <CircleClose {...props} method="stroke" />,
  },
  {
    name: 'pin',
    icon: (props: IconProps) => <Pin {...props} method="stroke" />,
  },
  {
    name: 'phone',
    icon: (props: IconProps) => <Phone {...props} method="fill" />,
  },
  {
    name: 'short-email',
    icon: (props: IconProps) => <ShortEmail {...props} method="fill" />,
  },
  {
    name: 'info',
    icon: (props: IconProps) => <Info {...props} method="fill" />,
  },
  {
    name: 'edit',
    icon: (props: IconProps) => <Edit {...props} method="fill" />,
  },
  {
    name: 'trash',
    icon: (props: IconProps) => <Trash {...props} method="fill" />,
  },
  {
    name: 'menu-home',
    icon: (props: IconProps) => <MenuHome {...props} method="fill" />,
  },
  {
    name: 'menu-profile',
    icon: (props: IconProps) => <MenuProfile {...props} method="fill" />,
  },
  {
    name: 'menu-faq',
    icon: (props: IconProps) => <MenuFAQ {...props} method="fill" />,
  },
  {
    name: 'menu-payment',
    icon: (props: IconProps) => <MenuPayment {...props} method="fill" />,
  },
  {
    name: 'menu-review',
    icon: (props: IconProps) => <MenuReview {...props} method="fill" />,
  },
  {
    name: 'menu-widget',
    icon: (props: IconProps) => <MenuWidget {...props} method="fill" />,
  },
  {
    name: 'menu-package',
    icon: (props: IconProps) => <MenuPackage {...props} method="fill" />,
  },
  {
    name: 'menu-media',
    icon: (props: IconProps) => <MenuMedia {...props} method="fill" />,
  },
  {
    name: 'menu-setting',
    icon: (props: IconProps) => <MenuSetting {...props} method="fill" />,
  },
  {
    name: 'menu-user',
    icon: (props: IconProps) => <MenuUser {...props} method="stroke" />,
  },
  {
    name: 'menu-propfirm',
    icon: (props: IconProps) => <MenuPropFirm {...props} method="stroke" />,
  },
  {
    name: 'menu-article',
    icon: (props: IconProps) => <MenuArticle {...props} method="stroke" />,
  },
  {
    name: 'menu-author',
    icon: (props: IconProps) => <MenuAuthor {...props} method="stroke" />,
  },
  {
    name: 'mastercard',
    icon: (props: IconProps) => <MasterCard {...props} method="fill" />,
  },
  {
    name: 'visa',
    icon: (props: IconProps) => <Visa {...props} method="fill" />,
  },
  {
    name: 'lock',
    icon: (props: IconProps) => <Lock {...props} method="fill" />,
  },
  {
    name: 'bell',
    icon: (props: IconProps) => <Bell {...props} method="stroke" />,
  },
  {
    name: 'users',
    icon: (props: IconProps) => <Users {...props} method="stroke" />,
  },
  {
    name: 'firms',
    icon: (props: IconProps) => <Firms {...props} method="stroke" />,
  },
  {
    name: 'notification-success',
    icon: (props: IconProps) => <NotificationSuccess {...props} method="fill" />,
  },
  {
    name: 'notification-warning',
    icon: (props: IconProps) => <NotificationWarning {...props} method="fill" />,
  },
  {
    name: 'notification-danger',
    icon: (props: IconProps) => <NotificationDanger {...props} method="fill" />,
  },
  {
    name: 'notification-info',
    icon: (props: IconProps) => <NotificationInfo {...props} method="fill" />,
  },
  {
    name: 'folder-upload',
    icon: (props: IconProps) => <FolderUpload {...props} method="fill" />,
  },
];

const Icon = (props: IconProps): JSX.Element => {
  if (props.icon === undefined) return <></>;
  const filteredIcon = icons.filter((icon) => props.icon === icon.name);

  if (filteredIcon.length === 0) return <></>;

  const iconSvg = filteredIcon[0].icon;

  let width = 0;
  let height = 0;

  if (props.size === 'xxl') {
    width = 80;
    height = 80;
  } else if (props.size === 'xl') {
    width = 24;
    height = 24;
  } else if (props.size === 'lg') {
    width = 20;
    height = 20;
  } else if (props.size === 'md') {
    width = 16;
    height = 16;
  } else if (props.size === 'sm') {
    width = 14;
    height = 14;
  } else if (props.size === 'xs') {
    width = 12;
    height = 12;
  }

  let newProps = props;
  if (width !== 0 && height !== 0) {
    newProps = {
      width: props.width || width,
      height: props.height || height,
      ...props,
    };
  }

  return <>{iconSvg(newProps)}</>;
};

export default Icon;
