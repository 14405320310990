import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router, Navigate, Outlet, useNavigate } from 'react-router-dom';
import EventSource from 'eventsource';
import AppLayout from './layouts/app-layout';
import DashboardLayout from 'layouts/dashboard-layout';
import AdminLayout from 'layouts/admin-layout';
import Loading from 'components/Loading';
import ComponentLoader from 'components/ComponentLoader';
import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH, DASHBOARD_PREFIX_PATH, IS_ADMIN_APP } from './configs/config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { INotification, IUser, USER_ROLE } from 'types';
import ActionTypes from 'store/constants/ActionTypes';

const Login = lazy(() => ComponentLoader(() => import('pages/auth/login')));

export const ProtectedRoutes = ({ role }: { role: USER_ROLE }) => {
  const { loggedIn, currentCompany, currentUser } = useAppSelector((state) => state.auth);
  if (!loggedIn) {
    if( role === USER_ROLE.SUPERADMIN )
      return <Navigate to={`${ADMIN_PREFIX_PATH}/login`} />;
    return <Navigate to={`${APP_PREFIX_PATH}/login`} />;
  }

  if (
    (role === USER_ROLE.COMPANY && !currentCompany) ||
    (role === USER_ROLE.USER && !currentUser) ||
    (role === USER_ROLE.SUPERADMIN && (!currentUser || (currentUser as IUser).role !== USER_ROLE.SUPERADMIN))
  )
    return <Navigate to={`${APP_PREFIX_PATH}`} />;

  return <Outlet />;
};

function MainRouter() {
  const dispatch = useAppDispatch();
  const { loggedIn, accessToken } = useAppSelector((state) => state.auth);

  useEffect(() => {
    function getRealtimeData(data: INotification) {
      // process the data here,
      // then pass it to state to be rendered
      dispatch({
        type: ActionTypes.ADD_NOTIFICATION,
        payload: data
      })
    }

    if (!loggedIn) {
      dispatch({
        type: ActionTypes.CLEAR_NOTIFICATION,
      })
    }

    if (loggedIn && accessToken) {
      const serverUrl = process.env.REACT_APP_SERVER_URL
        ? process.env.REACT_APP_SERVER_URL
        : 'http://localhost:4000/api';
      const sse = new EventSource(`${serverUrl}/notification/sse`, {
        headers: { Authorization: 'Bearer ' + accessToken },
      });
      sse.onmessage = (e) => getRealtimeData(JSON.parse(e.data));
      sse.onerror = (e) => {
        // error log here
        console.log('error sse', e);
        sse.close();
      };

      const sseLogout = new EventSource(`${serverUrl}/auth/force-logout`, {
        headers: { Authorization: 'Bearer ' + accessToken },
      });
      sseLogout.onmessage = (e) => {
        dispatch({
          type: ActionTypes.AUTH_LOGOUT.SUCCESS,
        });
      }
      sseLogout.onerror = (e) => {
        // error log here
        console.log('error sse', e);
        sseLogout.close();
      };
      return () => {
        sse.close();
        sseLogout.close();
      };
    }
  }, [loggedIn, accessToken]);

  return (
    <>
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path={`${ADMIN_PREFIX_PATH}/login`} element={<Login isAdmin/>} />
            <Route element={<ProtectedRoutes role={USER_ROLE.SUPERADMIN} />}>
              <Route path={`${ADMIN_PREFIX_PATH}/*`} element={<AdminLayout />} />
            </Route>
            {!IS_ADMIN_APP &&
            <>
            <Route element={<ProtectedRoutes role={USER_ROLE.COMPANY} />}>
              <Route path={`${DASHBOARD_PREFIX_PATH}/*`} element={<DashboardLayout />} />
            </Route>
            <Route path={`${APP_PREFIX_PATH}/*`} element={<AppLayout />} />
            </>
            }
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default MainRouter;
