import React, { FC, CSSProperties } from 'react';

/*
    Card Props

    title: title of Card (top left)
    action: render function for Card action (top right)
*/

interface CardProps {
  className?: string;
  headerClass?: string;
  title?: string;
  style?: CSSProperties;
  action?: () => JSX.Element;
  onClick?: () => void;
}

const Card: FC<CardProps> = ({
  className = '',
  title = '',
  headerClass = 'justify-content-between',
  style,
  action,
  onClick,
  children,
}) => {
  return (
    <div className={`co-card d-flex flex-column ${className}`} style={style} onClick={onClick}>
      {(title !== '' || action !== undefined) && (
        <div className={`co-card-header d-flex align-items-center ${headerClass}`}>
          <div className="co-card-subtitle">{title}</div>
          {action !== undefined && <div className="co-card-action">{action()}</div>}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
