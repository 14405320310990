import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import ComponentLoader from 'components/ComponentLoader';
import Header from './header';
import Footer from './footer';
import { ProtectedRoutes } from '../../router';
import { USER_ROLE } from 'types';

const Home = lazy(() => ComponentLoader(() => import('pages/home')));
const Login = lazy(() => ComponentLoader(() => import('pages/auth/login')));
const Register = lazy(() => ComponentLoader(() => import('pages/auth/register')));
const ForgotPassword = lazy(() => ComponentLoader(() => import('pages/auth/forgot-password')));
const ResetPassword = lazy(() => ComponentLoader(() => import('pages/auth/reset-password')));
const FirmSignUp = lazy(() => ComponentLoader(() => import('pages/auth/firm-signup')));
const Contact = lazy(() => ComponentLoader(() => import('pages/misc/contact')));
const VerifyAccount = lazy(() => ComponentLoader(() => import('pages/auth/confirm')));
const PropFirms = lazy(() => ComponentLoader(() => import('pages/prop-firms')));
const PropFirmProfile = lazy(() => ComponentLoader(() => import('pages/prop-firms/profile')));
const PropFirmsCompare = lazy(() => ComponentLoader(() => import('pages/compare')));
const Mission = lazy(() => ComponentLoader(() => import('pages/misc/mission')));
const News = lazy(() => ComponentLoader(() => import('pages/misc/news')));
const HowTo = lazy(() => ComponentLoader(() => import('pages/misc/how-to')));
const UserFAQs = lazy(() => ComponentLoader(() => import('pages/misc/user-faqs')));
const ServiceProviderGuide = lazy(() => ComponentLoader(() => import('pages/misc/service-guide')));
const PropFirmsGetListed = lazy(() => ComponentLoader(() => import('pages/misc/get-listed')));
const PropFirmFAQs = lazy(() => ComponentLoader(() => import('pages/misc/propfirm-faqs')));
const Legal = lazy(() => ComponentLoader(() => import('pages/misc/legal')));
const PrivacyPolicy = lazy(() => ComponentLoader(() => import('pages/misc/privacy-policy')));
const Terms = lazy(() => ComponentLoader(() => import('pages/misc/terms')));
const CookiePreference = lazy(() => ComponentLoader(() => import('pages/misc/cookie-preference')));

const Profile = lazy(() => ComponentLoader(() => import('pages/profile')));

export const AppLayout = () => {
  const location = useLocation();

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return (
    <div className="co-main">
      <Suspense fallback={<Loading />}>
      <div className="co-content d-flex flex-column">
        <Header />
        <div className="co-content-main d-flex flex-column flex-1">
            <Routes>
              <Route path={`/login`} element={<Login />} />
              <Route path={`/register`} element={<Register />} />
              <Route path={`/forgot-password`} element={<ForgotPassword />} />
              <Route path={`/firm-signup`} element={<FirmSignUp />} />
              <Route path={`/contact`} element={<Contact />} />
              <Route path={`/confirm/:token`} element={<VerifyAccount />} />
              <Route path={`/reset-password/:token`} element={<ResetPassword />} />
              <Route path={`/prop-firm`}>
                <Route path={`:id`} element={<PropFirmProfile />} />
                <Route path="" element={<PropFirms />} />
              </Route>
              <Route path={`/compare`} element={<PropFirmsCompare />} />
              <Route element={<ProtectedRoutes role={USER_ROLE.USER} />}>
                <Route path={`/profile`} element={<Profile />} />
              </Route>
              <Route path={`/mission`} element={<Mission />} />
              <Route path={`/news`} element={<News />} />
              <Route path={`/how-to`} element={<HowTo />} />
              <Route path={`/user-faq`} element={<UserFAQs />} />
              <Route path={`/propfirm-faq`} element={<PropFirmFAQs />} />
              <Route path={`/service-guide`} element={<ServiceProviderGuide />} />
              <Route path={`/get-listed`} element={<PropFirmsGetListed />} />
              <Route path={`/legal`} element={<Legal />} />
              <Route path={`/privacy`} element={<PrivacyPolicy />} />
              <Route path={`/terms`} element={<Terms />} />
              <Route path={`/cookie-preference`} element={<CookiePreference />} />
              <Route path={`/`} element={<Home />} />
            </Routes>
        </div>
        <Footer />
      </div>
      </Suspense>
    </div>
  );
};

export default AppLayout;
