import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import 'swiper/modules/effect-coverflow/effect-coverflow.min.css';
import 'react-calendar/dist/Calendar.css';
import 'react-phone-input-2/lib/style.css';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import 'flag-icons/css/flag-icons.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './assets/scss/index.scss';
import { store, persistor } from './store';
import MainRouter from 'router';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Scrollbar, Mousewheel, EffectCoverflow } from 'swiper';
import Loading from 'components/Loading';

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Scrollbar, Mousewheel, EffectCoverflow]);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
function App() {
  return (
    <div className="co-mainwrap">
      <ReactNotifications />

      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <MainRouter />
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
