import { AnyAction } from 'redux';
import ActionTypes from '../constants/ActionTypes';
import { ICompanyInfo, ID, IUser } from 'types';
import { createCookie, readCookie, eraseCookie } from 'helpers/cookie';

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const access_token = readCookie(ACCESS_TOKEN_KEY) || '';
const refresh_token = readCookie(REFRESH_TOKEN_KEY) || '';

interface AuthState {
  loggedIn: boolean;
  currentUser?: IUser | null;
  currentCompany?: ICompanyInfo | null;
  accessToken: string | null;
  refreshToken: string | null;
  error: string;
  forceLogout: number;
}

const INIT_STATE: AuthState = {
  loggedIn: false,
  currentUser: null,
  currentCompany: null,
  accessToken: access_token,
  refreshToken: refresh_token,
  error: '',
  forceLogout: 0,
};

const App = (state = INIT_STATE, action: AnyAction) => {
  const { payload } = action;
  const errorMsg = payload?.error?.message;

  switch (action.type) {
    case ActionTypes.AUTH_LOGIN.SUCCESS:
      createCookie(ACCESS_TOKEN_KEY, payload.access_token, 12);
      createCookie(REFRESH_TOKEN_KEY, payload.refresh_token, 12);
      return {
        ...state,
        loggedIn: true,
        accessToken: payload.access_token,
        refreshToken: payload.refresh_token,
        currentUser: payload.user as IUser,
        currentCompany: payload.company as ICompanyInfo,
        error: '',
      };
    case ActionTypes.AUTH_LOGIN.FAILURE:
    case ActionTypes.AUTH_LOGOUT.SUCCESS:
      eraseCookie(ACCESS_TOKEN_KEY);
      eraseCookie(REFRESH_TOKEN_KEY);
      return {
        ...state,
        loggedIn: false,
        accessToken: '',
        refreshToken: '',
        currentUser: null,
        currentCompany: null,
        error: errorMsg,
        forceLogout: state.forceLogout + 1,
      };
    case ActionTypes.UPDATE_COMPANY_PROFILE:
      return {
        ...state,
        currentCompany: payload as ICompanyInfo,
      };
    case ActionTypes.UPDATE_USER_PROFILE:
      return {
        ...state,
        currentUser: payload as IUser,
      };
    default:
      return state;
  }
};

export default App;
