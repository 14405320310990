import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { ADMIN_PREFIX_PATH } from 'configs/config';
import { useAppDispatch, useAppSelector } from 'hooks';
import Icon from 'components/Icon';
import { Button } from 'components/Base';
import { ReactComponent as ImgLogo } from 'assets/images/logo.svg';
import { ReactComponent as ImgLogoIcon } from 'assets/images/logo-icon.svg';
import ActionTypes from 'store/constants/ActionTypes';

const menus = [
  {
    url: '/home',
    caption: 'Home',
    icon: 'menu-home',
  },
  {
    url: '/user',
    caption: 'User',
    icon: 'menu-user',
  },
  {
    url: '/prop-firm',
    caption: 'Prop Firms',
    icon: 'menu-propfirm',
  },
  // {
  //   url: '/article',
  //   caption: 'News Artciles',
  //   icon: 'menu-article',
  // },
  // {
  //   url: '/author',
  //   caption: 'News Authors',
  //   icon: 'menu-author',
  // },
];

export const SideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isCurrent = (url: string) => {
    return location.pathname.startsWith(url);
  };

  const handleLogout = () => {
    dispatch({
      type: ActionTypes.AUTH_LOGOUT.SUCCESS,
    });
    navigate('/');
  };

  return (
    <div className="co-admin-left-panel d-flex flex-column">
      <div className="co-brand d-lg-block d-none" >
        <ImgLogo />
      </div>
      <div className="co-brand d-block d-lg-none" >
        <ImgLogoIcon />
      </div>

      <div className="co-admin-navbar-links">
        {menus.map((menu, idx) => (
          <Link
            to={`${ADMIN_PREFIX_PATH}${menu.url}`}
            className={`admin-sidebar-navbar-link d-flex align-items-center ${
              isCurrent(ADMIN_PREFIX_PATH + menu.url) ? 'current' : ''
            }`}
            key={idx}
            title={menu.caption}
          >
            <div className="link-icon d-flex align-items-center justify-content-center">
              <Icon icon={menu.icon} />
            </div>
            <div className="link-label ms-3">{menu.caption}</div>
          </Link>
        ))}
      </div>

      <div className="divider mt-auto" />
      <div className="logout-wrapper">
        <Button variant="link" icon="arrow-left" size="auto" onClick={handleLogout}>
          <span className="text-bold d-none d-lg-block">Logout</span>
        </Button>
      </div>
    </div>
  );
};

export default SideNav;
