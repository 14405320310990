import React, { useEffect, useState, useRef, FC, ReactNode, CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import Icon from '../Icon';
import { usePopper } from 'react-popper';
import { Placement, PositioningStrategy } from '@popperjs/core';

/*
    Dropdown Props

    popupPos: bottom-left, "bottom-right", "bottom-center"
                top-left, top-right, top-center are not implemented here
    hideToggle: hide/show dropdown triangle icon
    showArrow: show/hide Popup's indicator
    onToggle: event for show/hide Popup
*/

// type MENU_POS = "bottom-left" | "bottom-right" | "bottom-center" | "top-left" | "top-right" | "top-center"

interface DropdownProps {
  className?: string;
  buttonClassName?: string;
  popupClassName?: string;
  popupPos?: Placement;
  badge?: number;
  hideToggle?: boolean;
  showArrow?: boolean;
  disabled?: boolean;
  autoHidePopup?: boolean;
  usePortal?: boolean;
  dropdownIcon?: string;
  manual?: boolean;
  dropdownVisible?: boolean;
  strategy?: PositioningStrategy;
  onToggle?: (visible: boolean, popperBottom: boolean) => void;
  button: () => ReactNode;
  children: React.ReactNode;
}

const Dropdown: FC<DropdownProps> = ({
  className = '',
  onToggle,
  buttonClassName = '',
  popupClassName = '',
  popupPos = 'bottom-start',
  badge,
  hideToggle = false,
  showArrow = false,
  disabled = false,
  autoHidePopup = true,
  usePortal = true,
  dropdownIcon = 'dropdown',
  manual = false,
  dropdownVisible = false,
  strategy,
  button,
  children,
}) => {
  const portalRoot = typeof window === 'object' ? document.getElementById('popup') : null;

  const [visible, setVisible] = useState(manual ? dropdownVisible : false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(containerRef.current, menuElement, {
    placement: popupPos,
    strategy: strategy,
  });

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        containerRef.current &&
        event.target instanceof Node &&
        !containerRef.current.contains(event.target) &&
        menuElement &&
        !menuElement.contains(event.target)
      ) {
        // if( visible ) {
        setTimeout(() => {
          setVisible(false);
        }, 50);
        // }
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [menuElement]);

  const handleToggle = () => {
    if (manual || disabled) return;

    setVisible(!visible);
  };

  useEffect(() => {
    const popper = attributes['popper'];
    let popperBottom = true;
    if (popper) popperBottom = popper['data-popper-placement'].startsWith('bottom');
    if (onToggle) onToggle(visible, popperBottom);
  }, [visible, onToggle, attributes]);

  useEffect(() => {
    if (manual) {
      setVisible(dropdownVisible);
    }
  }, [manual, dropdownVisible]);

  // useEffect(() => {
  //   if (visible) {
  //     if (menuElement) {
  //       scrollIntoView(menuElement, {
  //         scrollMode: 'if-needed',
  //         block: 'nearest',
  //         inline: 'nearest',
  //       });
  //     }
  //     // menuRef.current?.scrollIntoView(false);
  //   }
  // }, [visible, menuElement]);

  const menu = (
    <div
      ref={setMenuElement}
      className={`co-dropdown-list ${popupClassName} ${usePortal || strategy === 'fixed' ? '' : 'w-100'} ${
        showArrow ? 'show-arrow' : ''
      }`}
      style={styles.popper}
      {...attributes.popper}
      tabIndex={0}
      onClick={() => {
        if (autoHidePopup) {
          setVisible(false);
        }
      }}
    >
      {children}
    </div>
  );

  const renderMenu = usePortal && portalRoot ? createPortal(menu, portalRoot) : menu;

  return (
    <div className={`co-dropdown ${className}`} ref={containerRef}>
      <div className={`co-dropdown-toggle w-100 d-flex align-items-center ${buttonClassName} ${disabled && 'disabled'}`} onClick={handleToggle}>
        {button()}
        {hideToggle === false && (
          <div className="co-dropdown-toggle-icon ms-2">
            <Icon icon={dropdownIcon} />
          </div>
        )}
      </div>
      {badge && <div className="badge">{badge}</div>}
      {visible && renderMenu}
    </div>
  );
};

export default Dropdown;
