import { ComponentType } from 'react';

const ComponentLoader = (lazyComponent: () => Promise<{ default: ComponentType<any> }>, attemptsLeft = 3) => {
  return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1000 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          ComponentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1000);
      });
  });
};

export default ComponentLoader;
