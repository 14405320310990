import React, { useState, useEffect, useCallback, FC, ReactNode, useMemo } from 'react';
import { ElementWithIconProps } from './interface';
import Dropdown from './Dropdown';
import { CheckBox, Input, RadioBox } from '.';
import { TYPE_SIZE, MapCheck } from 'types';
import _cloneDeep from 'lodash/cloneDeep';
import Icon from '../Icon';
import _ from 'lodash';

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface SelectProps extends ElementWithIconProps {
  title?: string;
  value: string | number | MapCheck | undefined;
  label?: string | ReactNode | ReactNode[];
  type?: 'normal' | 'checkbox' | 'radiobox';
  height?: TYPE_SIZE;
  placeholder?: string;
  rtl?: boolean;
  options: SelectOption[];
  buttonClassName?: string;
  fixed?: boolean;
  noBorder?: boolean;
  error?: string;
  readOnly?: boolean;
  search?: boolean;
  renderItem?: (item: SelectOption, index?: number) => ReactNode;
}

const Select: FC<SelectProps> = ({
  className = '',
  value,
  label,
  options,
  type = 'normal',
  disabled = false,
  height = 'lg',
  placeholder = '',
  buttonClassName = '',
  icon,
  iconSize,
  renderItem,
  noBorder = false,
  fixed,
  rtl,
  style,
  error,
  readOnly,
  search = false,
  onChange,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuPos, setMenuPos] = useState('bottom');
  const [filterKey, setFilterKey] = useState('');

  const getOptionTitle = () => {
    if (renderItem) {
      const item = options.find((item) => item.value === value);
      if (item) return renderItem(item);
    }
    if (type === 'checkbox') {
      const mapValue: MapCheck = value as MapCheck;
      const checkedItems: Array<string> = [];
      Object.entries(mapValue).map(([key, { checked }]) => {
        if (checked) {
          checkedItems.push(options.find((item) => item.value === key)?.label || '');
        }
      });

      return checkedItems.join(', ');
    }

    return options.find((item) => item.value === value)?.label;
  };

  const selectDiv = () => {
    const optionTitle = getOptionTitle();
    return (
      <div className="input d-flex flex-1" style={{ ...style, minWidth: 0 }}>
        <div className="co-select-text w-100">
          {value === '' || value === null || (type === 'checkbox' && optionTitle === '') ? (
            <span className="co-select-placeholder">{placeholder ?? (label ? `Enter ${label}` : '')}</span>
          ) : (
            optionTitle
          )}
        </div>
      </div>
    );
  };

  const handleChange = (val: string | number) => {
    if (onChange) onChange(val);
  };

  const handleCheck = (val: string | number, checked: boolean) => {
    if ((value as MapCheck)[val]) {
      (value as MapCheck)[val].checked = checked;
      const newValue = _cloneDeep(value);
      if (onChange) onChange(newValue);
    }
  };

  const handleToggle = useCallback((visible: boolean, bottom: boolean) => {
    setMenuOpen(visible);
    setMenuPos(bottom ? 'bottom' : 'top');
    setFilterKey('');
  }, []);
  
  const filteredOptions = useMemo(() => {
    const lowcase = filterKey.toLocaleLowerCase();
    if( lowcase.length === 0 ) return options;
    return options.filter((item) => item.label.toLocaleLowerCase().includes(lowcase))
  }, [filterKey, options]);

  return (
    <div className={className}>
      {label && <div className="co-label">{label}</div>}
      <Dropdown
        className={`co-select ${disabled && 'disabled'} ${error && 'error'} ${noBorder && 'no-border'} ${
          menuOpen && 'open'
        } ${menuPos} co-select-${height}`}
        buttonClassName={`h-100 px-3 ${buttonClassName}`}
        button={selectDiv}
        disabled={disabled || readOnly}
        autoHidePopup={type !== 'checkbox'}
        dropdownIcon={icon ?? "dropdown"}
        strategy={fixed ? 'fixed' : 'absolute'}
        usePortal={false}
        onToggle={handleToggle}
      >
        {search &&
        <Input className="filter-input p-2" height="sm" placeholder="Search" value={filterKey} onChangeValue={setFilterKey} autoFocus />
        }
        {filteredOptions.map((item, idx) => {
          if (renderItem) {
            return <div key={item.value}>{renderItem(item, idx)}</div>;
          } else if (type === 'normal' || type === undefined) {
            return (
              <div key={item.value} className="co-select-item" onClick={() => handleChange(item.value)}>
                {item.label}
              </div>
            );
          } else if (type === 'radiobox') {
            return (
              <RadioBox
                key={item.value}
                className="co-select-item"
                value={item.value}
                current={value as string | number}
                title={item.label}
                rtl={rtl}
                onChange={handleChange}
              />
            );
          } else if (type === 'checkbox') {
            return (
              <CheckBox
                key={item.value}
                className="co-select-item"
                checked={(value as MapCheck)[item.value]?.checked}
                title={item.label}
                onChange={(val) => handleCheck(item.value, val)}
              />
            );
          }
          return <></>;
        })}
      </Dropdown>
      
      {error && <div className="co-field-error">{_.capitalize(error)}</div>}
    </div>
  );
};

export default Select;
