// src/hooks.ts
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DASHBOARD_PREFIX_PATH } from 'configs/config';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { store } from 'store';

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;

// Use throughout your app instead of `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDetectAdminPage = (): boolean => {
  const [isAdmin, setAdmin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setAdmin(location.pathname.startsWith(DASHBOARD_PREFIX_PATH));
  }, []);

  return isAdmin;
};
