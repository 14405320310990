import React, { useCallback, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Row, Col } from 'react-bootstrap';
import { ReactComponent as ImgLogo } from 'assets/images/logo.svg';
import { ReactComponent as IconInstagram } from 'assets/images/social-instagram.svg';
import { ReactComponent as IconTwitter } from 'assets/images/social-twitter.svg';
import { ReactComponent as IconLinkedIn } from 'assets/images/social-linkedin.svg';

export const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [termsAgree, setTermsAgree] = useState(false);

  return (
    <div className="co-footer">
      <div className="container footer-content d-flex flex-column flex-lg-row">
        <div className="flex-0">
          <Link to="/">
            <ImgLogo />
          </Link>
          <div className="footer-social-links d-flex">
            <div className="text-title text-regbold">Follow our socials</div>
            <div className="splitter" />
            <Link to="#" className="me-3">
              <IconInstagram />
            </Link>
            <Link to="#" className="me-3">
              <IconTwitter />
            </Link>
            <Link to="#" className="me-3">
              <IconLinkedIn />
            </Link>
          </div>
          {/* <div>
            <div className="text-title text-large text-bold mb-3">Newsletter</div>
            <div className="d-flex">
              <Input
                placeholder="Enter Your Email*"
                height="lg"
                className="me-2 w-100"
                inputClassName="no-border"
                style={{ maxWidth: '236px' }}
              />
              <Button variant="primary" size="lg" disabled={!termsAgree}>
                Submit
              </Button>
            </div>
          </div> */}
          {/* <div className="mt-3 d-flex align-items-center">
            <CheckBox variant="secondary" checked={termsAgree} onChange={setTermsAgree} circle />
            <div className="ms-2 font-inter text-title text-tiny">
              I agree with <Link to="#">Privacy Policy</Link> and <Link to="#">Terms of Conditions</Link>
            </div>
          </div> */}
        </div>

        <Row className="footer-content-right">
          <Col sm={12} md={6} lg={3}>
            <div className="text-title text-bold text-large mb-4">About</div>
            <div>
              <Link to="/mission" className="d-block text-title text-regular mb-3">
                Mission
              </Link>
              <Link to="/news" className="d-block text-title text-regular mb-3">
                News
              </Link>
              <Link to="/how-to" className="d-block text-title text-regular mb-3">
                How Prop Trust Works
              </Link>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="text-title text-bold text-large mb-4">Traders</div>
            <div>
              <Link to="/prop-firm" className="d-block text-title text-regular mb-3">
                Browse All Prop Firms
              </Link>
              <Link to="/user-faq" className="d-block text-title text-regular mb-3">
                User FAQs
              </Link>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="text-title text-bold text-large mb-4">Prop Firms</div>
            <div>
              <Link to="/service-guide" className="d-block text-title text-regular mb-3">
                Service Provider Guide
              </Link>
              <Link to="/get-listed" className="d-block text-title text-regular mb-3">
                Get Listed
              </Link>
              <Link to="/propfirm-faq" className="d-block text-title text-regular mb-3">
                Prop Firm FAQs
              </Link>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="text-title text-bold text-large mb-4">Contact</div>
            <div>
              <Link to="/contact" className="d-block text-title text-regular mb-3">
                Contact Us
              </Link>
            </div>
          </Col>
        </Row>
      </div>

      <div className="footer-bottom d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <div>©2023 Proptrust.com</div>
          <div className="d-flex">
            <Link to="/legal" className="me-4 text-white text-regular">
              Legal
            </Link>
            <Link to="/privacy" className="me-4 text-white text-regular">
              Privacy Policy
            </Link>
            <Link to="/terms" className="me-4 text-white text-regular">
              Terms & Conditions
            </Link>
            <Link to="/cookie-preference" className="text-white text-regular">
              Cookie Preferences
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
