import { IPackage } from "types";

export const shortName = (str: string): string => {
  const arrNames = str.split(' ');
  if (arrNames.length >= 2) {
    return (arrNames[0][0] + arrNames[1][0]).toUpperCase();
  }

  return str.substring(0, 2).toUpperCase();
};

export const truncate = (str: string, n: number): string => {
  return str.length > n ? str.substring(0, n - 1) + '...' : str;
};

export const formatMoney = (val: string | number | undefined, decimal?: number, currency?: string): string => {
  if (val === undefined) return '';

  const num = typeof val === 'string' ? parseFloat(val) : val;

  const parts = num.toFixed(decimal).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (currency || '') + parts.join('.');
};

export const parseMessage = (msg: string): string => {
  let result = '';
  let isEnd = false;
  let subMsg = msg.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');

  do {
    const posHttp = subMsg.search('http://');
    const posHttps = subMsg.search('https://');

    if (posHttp === -1 && posHttps === -1) {
      isEnd = true;
      result += subMsg;
    } else {
      let firstPos = 0;
      if (posHttp === -1) {
        firstPos = posHttps;
      } else if (posHttps === -1) {
        firstPos = posHttp;
      } else {
        firstPos = posHttp < posHttps ? posHttp : posHttps;
      }
      result += subMsg.slice(0, firstPos);
      subMsg = subMsg.slice(firstPos);

      const posSpace = subMsg.search(' ');
      let httpString = '';
      if (posSpace === -1) {
        httpString = subMsg;
        isEnd = true;
      } else {
        httpString = subMsg.slice(0, posSpace);
        subMsg = subMsg.slice(posSpace);
      }

      result += `<a href='${httpString}' target='_blank' class='message-anchor'>${httpString}</a>`;
    }
  } while (!isEnd);

  return result;
};

export const getShortCardNumber = (cardNumber: string | number): string => {
  const str = cardNumber.toString();
  return str.substr(-4);
};

export const checkValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const generatePassword = () => {
  return Math.random().toString(36).slice(-10);
}

export const getPackageSizes = (packages: IPackage[]) => {
  const sizes = packages.map((p) => p.capital)
  return sizes.filter((value, index, array) => array.indexOf(value) === index).sort((a, b) => a - b)
}