import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useLocation, NavLink, Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Navbar, Nav } from 'react-bootstrap';
import { APP_PREFIX_PATH, DASHBOARD_PREFIX_PATH } from 'configs/config';
import { Input, Dropdown, Button } from 'components/Base';
import Avatar from 'components/Avatar';
import Bookmark from 'components/Bookmark';
import Icon from 'components/Icon';
import ActionTypes from 'store/constants/ActionTypes';
import { useGetUserShortlist } from 'api/UserApi';
import { useGetProfile } from 'api/AuthApi';

import { ReactComponent as ImgLogo } from 'assets/images/logo.svg';

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(false);
  const { loggedIn, currentUser, currentCompany, forceLogout } = useAppSelector((state) => state.auth);
  const firstLoad = useRef(true)

  const { data: profile } = useGetProfile(loggedIn)
  const { data: userShortlist } = useGetUserShortlist(currentUser?.id)
  const location = useLocation();

  useEffect(() => {
    if( !firstLoad.current )
      navigate(`${APP_PREFIX_PATH}/`)
    else
      firstLoad.current = false;
  }, [forceLogout])

  const theme = useMemo(() => {
    if (
      location.pathname.startsWith('/prop-firm') ||
      location.pathname.startsWith('/compare') ||
      location.pathname.startsWith('/profile')
    )
      return 'white-theme';
    return '';
  }, [location.pathname]);

  const renderMenu = useCallback(
    () => profile?.user ? (
      <div className="d-flex align-items-center">
        <Avatar size="sm" color="yellow" src={profile?.user?.profilePictureUrl} name={`${profile?.user?.firstName} ${profile?.user?.lastName}`} />
        <div className="font-inter text-title text-small text-semibold ms-2">{`${profile?.user?.firstName} ${profile?.user?.lastName}`}</div>
      </div>
    ) : (
      <div className="d-flex align-items-center">
        <Avatar size="sm" color="yellow" name={profile?.company?.name} src={profile?.company?.logoUrl} />
        <div className="font-inter text-title text-small text-semibold ms-2">{profile?.company?.name}</div>
      </div>
    ),
    [profile]
  );

  const renderProfileMenu = useCallback(() => {
    const onClickSignOut = () => {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT.SUCCESS,
      });
      navigate('/');
    };

    const onClickProfile = () => {
      if( profile?.user ) {
        navigate(`${APP_PREFIX_PATH}/profile`);
      } else if ( profile?.company ) {
        navigate(`${DASHBOARD_PREFIX_PATH}/profile`);
      }
    }

    return (
      <Dropdown button={renderMenu} popupPos="bottom-end" hideToggle>
        <div className="d-flex flex-column gap-3 px-3 py-2">
          <div className="co-dropdown-link" onClick={onClickProfile}>
            <Icon icon="menu-profile" size='md' />
            <span className="text-bold ms-2">Profile</span>
          </div>
          <div className="divider" />
          <div className="co-dropdown-link" onClick={onClickSignOut}>
            <Icon icon="arrow-left" size='md' />
            <span className="text-bold ms-2">Sign Out</span>
          </div>
        </div>
      </Dropdown>
    );
  }, [renderMenu, dispatch, navigate]);

  const handleClickShortlist = () => {
    navigate(`${APP_PREFIX_PATH}/profile?tab=shortlist`);
  }

  return (
    <div className={`co-header ${theme}`}>
      <div className="container">
        <Navbar expanded={expanded} expand="lg" className="navbar-default" onToggle={() => setExpanded(!expanded)}>
          <div className="navbar-header d-flex align-items-center justify-content-between">
            <Navbar.Brand href="/" className="co-brand">
              <ImgLogo />
            </Navbar.Brand>

            <div className="d-flex align-items-center">
              <div className="d-flex d-lg-none align-items-center me-3">
                <Bookmark count={currentUser ? userShortlist?.meta.count : 0} disabled={!currentUser} onClick={handleClickShortlist} />
                <div className="splitter" />
                {!currentUser ? (
                  <Link to="/login">
                    <Button variant="primary" size="sm">
                      Login
                    </Button>
                  </Link>
                ) : (
                  renderProfileMenu()
                )}
              </div>

              <Navbar.Toggle aria-controls="responsive-navbar-nav" className="justify-content-end">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </Navbar.Toggle>
            </div>
          </div>

          <Navbar.Collapse id="responsive-navbar-nav" className="navbar-collapse justify-content-end">
            <Nav className="co-navmenu nav navbar-nav align-items-center">
              <NavLink to="/prop-firm" className="navbar-item text-title text-small">
                Prop Firms
              </NavLink>
              <NavLink to="/compare" className="navbar-item text-title text-small">
                Compare
              </NavLink>
              <NavLink to="/news" className="navbar-item text-title text-small">
                News
              </NavLink>

              <div className="d-none d-lg-flex align-items-center">
                <div className="splitter" />
                <Bookmark count={currentUser ? userShortlist?.meta.count : 0} disabled={!currentUser} onClick={handleClickShortlist} />
                <div className="splitter" />
                {!(currentUser || currentCompany) ? (
                  <Link to="/login">
                    <Button variant="primary" size="sm">
                      Login
                    </Button>
                  </Link>
                ) : (
                  renderProfileMenu()
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
