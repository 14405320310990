import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Loading from 'components/Loading';
import ComponentLoader from 'components/ComponentLoader';
import { DASHBOARD_PREFIX_PATH } from 'configs/config';
import Header from './header';
import SideNav from './sidenav';

const PropFirmHome = lazy(() => ComponentLoader(() => import('pages/dashboard/home')));
const PropFirmProfile = lazy(() => ComponentLoader(() => import('pages/dashboard/profile')));
const PropFirmFAQs = lazy(() => ComponentLoader(() => import('pages/dashboard/faq')));
const PropFirmPayment = lazy(() => ComponentLoader(() => import('pages/dashboard/payment')));
const PropFirmReviews = lazy(() => ComponentLoader(() => import('pages/dashboard/review')));
const PropFirmWidgets = lazy(() => ComponentLoader(() => import('pages/dashboard/widget')));
const PropFirmPackages = lazy(() => ComponentLoader(() => import('pages/dashboard/package')));
const PropFirmAddPackage = lazy(() => ComponentLoader(() => import('pages/dashboard/package/add-package')));
const PropFirmMedia = lazy(() => ComponentLoader(() => import('pages/dashboard/media')));
const PropFirmReports = lazy(() => ComponentLoader(() => import('pages/dashboard/report')));
const PropFirmSettings = lazy(() => ComponentLoader(() => import('pages/dashboard/setting')));
const PropFirmNotifications = lazy(() => ComponentLoader(() => import('pages/dashboard/notification')));

export const DashboardLayout = () => {
  const location = useLocation();

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return (
    <div className="co-main">
      <div className="co-content d-flex flex-column">
        <Header />
        <div className="co-content-wrapper d-flex container relative">
          <SideNav />
          <div className="co-content-dashboard d-flex flex-column flex-1 pb-5">
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path={`/home`} element={<PropFirmHome />} />
                <Route path={`/profile`} element={<PropFirmProfile />} />
                <Route path={`/faq`} element={<PropFirmFAQs />} />
                {/* <Route path={`/payment`} element={<PropFirmPayment />} /> */}
                <Route path={`/review`} element={<PropFirmReviews />} />
                {/* <Route path={`/widget`} element={<PropFirmWidgets />} /> */}
                <Route path={`/package/add`} element={<PropFirmAddPackage />} />
                <Route path={`/package/:packageId`} element={<PropFirmAddPackage />} />
                <Route path={`/package`} element={<PropFirmPackages />} />
                <Route path={`/media`} element={<PropFirmMedia />} />
                <Route path={`/report`} element={<PropFirmReports />} />
                <Route path={`/setting`} element={<PropFirmSettings />} />
                <Route path={`/notification`} element={<PropFirmNotifications />} />
                <Route path="*" element={<Navigate to={`${DASHBOARD_PREFIX_PATH}/home`} />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
