import React, { FC, FormEventHandler } from 'react';
import { createPortal } from 'react-dom';
import Button from './Button';
import { Spinner } from 'react-bootstrap';
import type { TYPE_SIZE } from 'types';

/*
    Modal Props
    
    title: Modal title
    size:   "md" -> max width 500px
            "lg" -> max width 700px
            "xl" -> max width 900px
    footer: caption for footer buttons
    full: no padding for modal content
    onOk: click event handler for Ok button 
    onClose: click event handler for Cancel/Close button 
*/

const portalRoot = document.getElementById('modal');
interface ModalProps {
  className?: string;
  title?: string;
  onClose?: () => void;
  onOk?: () => void;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  size?: TYPE_SIZE;
  full?: boolean;
  noFooter?: boolean;
  footer?: {
    custom?: () => React.ReactNode;
    ok?: string | null;
    cancel?: string | null;
    isOkDisabled?: boolean;
    isOkPending?: boolean;
    isCancelDisabled?: boolean;
    isCancelPending?: boolean;
  };
}

const Modal: FC<ModalProps> = ({
  children,
  title = '',
  size = 'md',
  full = false,
  footer,
  noFooter = false,
  className = '',
  onClose,
  onOk,
  onSubmit,
}) => {
  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleOk = () => {
    if (onOk) onOk();
  };

  const domModal = (
    <div className={`co-modal ${className}`}>
      <div className="co-modal-mask" onClick={handleClose}></div>

      <form className={`co-modal-container ${size}`} onSubmit={onSubmit}>
        <div className="co-modal-header d-flex justify-content-between">
          <div className="co-modal-title">{title}</div>
          <Button
            variant="hover-link"
            size="md"
            icon="close"
            iconPos="center"
            className="co-modal-close square"
            onClick={handleClose}
          />
        </div>

        <div className={`co-modal-content ${full ? 'content-full' : ''}`}>{children}</div>
        {!noFooter && (
          <div className="co-modal-footer d-flex justify-content-center justify-content-lg-end">
            {footer?.custom ? (
              footer?.custom()
            ) : (
              <>
                {footer?.cancel !== null && (
                  <Button
                    variant="hover-outline-primary"
                    size="md"
                    onClick={handleClose}
                    loading={footer?.isCancelPending}
                    disabled={footer?.isCancelDisabled}
                  >
                    {footer?.cancel || 'Cancel'}
                  </Button>
                )}
                {footer?.ok !== null && (
                  <Button
                    type={onSubmit ? 'submit' : 'button'}
                    variant="primary"
                    size="md"
                    className="ms-3"
                    onClick={handleOk}
                    loading={footer?.isOkPending}
                    disabled={footer?.isOkDisabled}
                  >
                    {footer?.ok || 'Ok'}
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </form>
    </div>
  );

  return portalRoot ? createPortal(domModal, portalRoot) : domModal;
};

export default Modal;
