import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { store } from 'store';
import ActionTypes from 'store/constants/ActionTypes';

export default class BaseApi {
  protected instance: AxiosInstance;
  protected REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL
    : 'http://localhost:4000/api';

  constructor() {
    this.instance = axios.create({
      baseURL: this.REACT_APP_SERVER_URL,
      responseType: 'json',
    });

    this.instance.interceptors.request.use(
      (config: AxiosRequestConfig<any>) => {
        // Do something before request is sent
        const token = this.getToken();
        if (config.headers && token && token !== '') config.headers['Authorization'] = 'Bearer ' + token;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        let err = {};
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          err = error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          err = error.request;
        } else {
          // Something happened in setting up the request that triggered an Error
          err = error;
        }
        if (error.response?.status === 401) {
          if( error.response?.request?.responseURL.startsWith(`${process.env.REACT_APP_SERVER_URL}/auth/refresh-token`) ) {
            store.dispatch({ type: ActionTypes.AUTH_LOGOUT.SUCCESS });
          } else if ( !error.response?.request?.responseURL.startsWith(`${process.env.REACT_APP_SERVER_URL}/auth`) ) {
            const refreshToken = store.getState().auth.refreshToken;
            if (refreshToken != null && refreshToken.length > 0) {
              try {
                const response = await this.refreshAuthToken(refreshToken)
                store.dispatch({
                  type: ActionTypes.AUTH_LOGIN.SUCCESS,
                  payload: response.data,
                });
                error.config.headers['Authorization'] = `Bearer ${(response.data as any).access_token}`;

                return axios(error.config);
              } catch (e : any) {
                if( e.response.status === 401 ) {
                  store.dispatch({ type: ActionTypes.AUTH_LOGOUT.SUCCESS });
                }
              }
            } else {
              store.dispatch({ type: ActionTypes.AUTH_LOGOUT.SUCCESS });
            }
          }
          
          return Promise.reject(err);
        } else {
          return Promise.reject(err);
        }
      }
    );
  }

  getToken() {
    return store.getState().auth.accessToken;
    // return localStorage.getItem('access_token');
  }

  refreshAuthToken (refreshToken: string) {
    return this.instance.get(`/auth/refresh-token/${refreshToken}`);
  }
}
