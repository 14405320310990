import { IWidget } from 'types';

export const fakeEssentialWidgets: IWidget[] = [
  {
    id: 1,
    name: 'Micro Review Count',
    template: '/images/widget/micro-review-count.svg',
    isPopular: true,
  },
  {
    id: 2,
    name: 'Horizontal',
    template: '/images/widget/horizontal.svg',
    isLocked: true,
  },
  {
    id: 3,
    name: 'Micro Button',
    template: '/images/widget/micro-button.svg',
  },
  {
    id: 4,
    name: 'Micro Combo',
    template: '/images/widget/micro-combo.svg',
    isLocked: true,
  },
  {
    id: 5,
    name: 'Micro Star',
    template: '/images/widget/micro-star.svg',
  },
  {
    id: 6,
    name: 'Mini',
    template: '/images/widget/mini.svg',
    isPopular: true,
  },
  {
    id: 7,
    name: 'Payout Proof',
    template: '/images/widget/payout-proof.svg',
    isPopular: true,
  },
];

export const fakeReviewWidgets: IWidget[] = [
  {
    id: 1,
    name: 'Evaluate',
    template: '/images/widget/evaluate.svg',
  },
  {
    id: 2,
    name: 'Review Collector',
    template: '/images/widget/review-collector.svg',
  },
];

export const fakeTestimonialWidgets: IWidget[] = [
  {
    id: 1,
    name: 'Carousel',
    template: '/images/widget/carousel.svg',
  },
  {
    id: 2,
    name: 'Mini Carousel',
    template: '/images/widget/mini-carousel.svg',
    isPopular: true,
  },
  {
    id: 3,
    name: 'Slider',
    template: '/images/widget/slider.svg',
  },
  {
    id: 4,
    name: 'Drop-down',
    template: '/images/widget/dropdown.svg',
  },
  {
    id: 5,
    name: 'List',
    template: '/images/widget/list.svg',
  },
  {
    id: 6,
    name: 'List Filtered',
    template: '/images/widget/list-filtered.svg',
  },
];
