function createCookie(name: string, value: string, hours: number) : void {
  let expires;
  if (hours) {
    const date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  } else expires = '';
  document.cookie = name + '=' + value + expires + '; path=/';
}

function readCookie(name: string): string | null {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

function eraseCookie(name: string) : void {
  createCookie(name, '', -1);
}

export { createCookie, readCookie, eraseCookie };
