import { IPaymentCard, IInvoice } from 'types';

export const fakeCards: IPaymentCard[] = [
  {
    id: 1,
    type: 'mastercard',
    cardNum: '1234567812348395',
    valid: {
      month: 6,
      year: 2025,
    },
    cvv: 503,
    holder: 'Axis Bank',
  },
  {
    id: 2,
    type: 'visa',
    cardNum: '1234567812346246',
    valid: {
      month: 3,
      year: 2025,
    },
    cvv: 234,
    holder: 'HDFC Bank',
  },
];

export const fakeInvoices: IInvoice[] = [
  {
    id: 1,
    type: 'Cameron Williamson',
    status: 'Paid',
    amount: 100,
    due_date: new Date(),
    createdAt: new Date(),
  },
  {
    id: 2,
    type: 'Cameron Williamson',
    status: 'Due',
    amount: 1,
    due_date: new Date(),
    createdAt: new Date(),
  },
  {
    id: 3,
    type: 'Cameron Williamson',
    status: 'Due',
    amount: 5,
    due_date: new Date(),
    createdAt: new Date(),
  },
  {
    id: 4,
    type: 'Cameron Williamson',
    status: 'Paid',
    amount: 10,
    due_date: new Date(),
    createdAt: new Date(),
  },
];
