import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useImperativeHandle,
  ChangeEventHandler,
  KeyboardEventHandler,
  KeyboardEvent,
  ReactNode,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
} from 'react';
import Icon from '../Icon';
import { ElementWithIconProps } from './interface';
import Dropdown from './Dropdown';
import { ID, TYPE_SIZE } from 'types';
import { ICountries, ICountry, isoCountries } from '../CountrySelect/countries';
import _ from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';

interface CountryItemProps {
  country: ICountry;
  onChange: (country: ICountry) => void;
}

const CountryItem = React.memo(({ country, onChange }: CountryItemProps) => {
  const handleClick = useCallback(() => {
    onChange(country);
  }, [onChange, country]);

  return (
    <div className="co-select-item" onClick={handleClick}>
      <span className={`fi fi-${country.iso.toLowerCase()} me-2`}></span>
      <span className="text-title me-2">{country.country}</span>
      <span className="text-desc">+{country.code}</span>
    </div>
  );
});
CountryItem.displayName = "CountryItem"

interface CountrySelectProps {
  country?: string;
  onChange?: (country: ICountry) => void;
}

const CountrySelect = function ({ country = 'US', onChange }: CountrySelectProps) {
  const [filterKey, setFilterKey] = useState('');
  const [filteredCountries, setFilteredCountries] = useState<ICountries>(isoCountries);

  useEffect(() => {
    const lowcase = filterKey.toLocaleLowerCase();
    setFilteredCountries({
      favorite: isoCountries.favorite.filter(
        (item) => item.country.toLocaleLowerCase().includes(lowcase) || item.code.includes(lowcase)
      ),
      others: isoCountries.others.filter(
        (item) => item.country.toLocaleLowerCase().includes(lowcase) || item.code.includes(lowcase)
      ),
    });
  }, [filterKey]);

  const countryCodeByIso = useMemo(() => {
    let result = isoCountries.favorite.find((c) => c.iso === country);
    if (result) return result.code;

    result = isoCountries.others.find((c) => c.iso === country);
    if (result) return result.code;

    return '';
  }, [country]);

  const renderSelected = useCallback(
    () => (
      <div className="co-phone-input-text d-flex align-items-center py-2">
        <span className={`fi fi-${country.toLowerCase()} me-2`}></span>
      </div>
    ),
    [country]
  );

  const handleToggleDropdown = useCallback(() => {
    setFilterKey('');
  }, []);

  const handleChangeCountry = useCallback(
    (country: ICountry) => {
      if (onChange) {
        onChange(country);
      }
    },
    [onChange]
  );

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => setFilterKey(e.target.value), []);

  const handleClickFilter = (e: any) => e.stopPropagation();

  return (
    <div className="d-flex align-items-center">
      <Dropdown button={renderSelected} dropdownIcon="dropdown4" onToggle={handleToggleDropdown}>
        <div className="co-phone-input">
          <input
            className="co-field filter-input p-2"
            value={filterKey}
            onChange={handleChange}
            onClick={handleClickFilter}
            // autoFocus
          />
          {filteredCountries.favorite.map((item) => (
            <CountryItem key={item.iso} country={item} onChange={handleChangeCountry} />
          ))}
          {filteredCountries.favorite.length > 0 && <div className="divider my-2" />}
          {filteredCountries.others.map((item) => (
            <CountryItem key={item.iso} country={item} onChange={handleChangeCountry} />
          ))}
        </div>
      </Dropdown>
      <div className="ms-2" style={{ cursor: 'default', marginTop: '1px' }}>
        +{countryCodeByIso}
      </div>
    </div>
  );
};

export interface SearchKey {
  id: ID;
  label: string;
  rendered?: ReactNode;
}

/*
    Input Props

    type: input, password, search ...
    label: label above input box
    height: 
        "xl" $input-height: 60px;
        "lg" $input-height: 53px;
        "md" $input-height-sm: 40px;
        "sm" $input-height-xs: 30px;
    prepend, append: 
*/

interface InputProps extends ElementWithIconProps, InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode | ReactNode[];
  height?: TYPE_SIZE;
  inputClassName?: string;
  prepend?: string | ReactNode;
  append?: string | ReactNode;
  candidates?: SearchKey[];
  error?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onChangeValue?: (v: string) => void;
  onChoose?: (val: SearchKey) => void;
  onClick?: () => void;
  onEnter?: () => void;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = function (
  {
    value = '',
    type = 'input',
    label,
    className = '',
    placeholder,
    icon = '',
    iconPos = 'left',
    iconSize = 'md',
    height = 'lg',
    inputClassName = '',
    prepend,
    append,
    candidates,
    required,
    style,
    error,
    onChange,
    onChangeValue,
    onClick,
    onChoose,
    onKeyUp,
    onEnter,
    ...others
  },
  ref
) {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchCandidates, setSearchCandidates] = useState<SearchKey[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [country, setCountry] = useState('US');
  const [phoneCode, setPhoneCode] = useState('1');

  useEffect(() => {
    if (inputRef.current) {
      if( type === 'phone' ) {
        const phoneNumber = parsePhoneNumber(value.toString(), 'US')
        if (phoneNumber) {
          if( phoneNumber.country ) setCountry(phoneNumber.country);
          setPhoneCode(phoneNumber.countryCallingCode);
          inputRef.current.value = phoneNumber.nationalNumber;
        } else if (value.toString().startsWith("+" + phoneCode)) {
          inputRef.current.value = value.toString().slice(phoneCode.length + 1);  
        }
      } else {
        inputRef.current.value = value.toString();
      }
    }
  }, [value, type, phoneCode]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event: Event) => {
    if (containerRef.current && event.target instanceof Node && !containerRef.current.contains(event.target)) {
      setSearchCandidates([]);
    }
  };

  const handleSearchItem = (value: SearchKey) => {
    if (onChoose) onChoose(value);
    setSearchCandidates([]);
  };

  const handleKeyUp = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) onKeyUp(evt);

    if (evt.key === 'Enter') {
      if (onEnter) onEnter();
    }
  };

  const handleChangeCountry = useCallback(
    (v: ICountry) => {
      if (onChangeValue && inputRef.current) {
        onChangeValue('+' + v.code + inputRef.current.value);
      }
      setCountry(v.iso);
      setPhoneCode(v.code);
    },
    [onChange, onChangeValue]
  );

  const phonePadding = useMemo(() => {
    return 86 + phoneCode.length * 8;
  }, [phoneCode]);

  return (
    <div className={'input ' + className} style={style}>
      {label && (
        <div className="co-label">
          {label}
          {required && <span className="fc-red">*</span>}
        </div>
      )}
      <div className="co-field-wrapper" ref={containerRef}>
        <input
          type={type === 'password' && showPassword ? 'input' : type}
          ref={ref || inputRef}
          placeholder={placeholder ?? (label ? `Enter ${label}` : '')}
          className={`co-field 
                                ${`co-field-${height}`} w-100 
                                ${type} 
                                ${icon !== '' && 'icon-' + iconPos} 
                                ${prepend && 'prepend'} 
                                ${append && 'append'} 
                                ${inputClassName}
                                ${showPassword && 'show-password'}`}
          onChange={(event) => {
            if (candidates && event.target.value.length >= 2) {
              setSearchCandidates(
                candidates.filter((item) => {
                  return item.label.toLowerCase().includes(event.target.value.toLowerCase());
                })
              );
            } else if (searchCandidates.length > 0) {
              setSearchCandidates([]);
            }
            if (onChange) onChange(event);
            if (onChangeValue) {
              if( type === 'phone' ) {
                if( event.target.value === '' )
                  onChangeValue('')
                else
                  onChangeValue('+' + phoneCode + event.target.value)
              } else {
                onChangeValue(event.target.value)
              }
            }
          }}
          onKeyUp={handleKeyUp}
          style={type === 'phone' ? { paddingLeft: phonePadding } : {}}
          {...others}
        />
        {type === 'phone' ? (
          <div className="co-field-icon-left d-inline-block">
            <CountrySelect country={country} onChange={handleChangeCountry} />
          </div>
        ) : (
          icon !== '' &&
          iconPos === 'left' && (
            <div
              className="co-field-icon-left d-inline-block me-2"
              onClick={(e) => {
                if (onClick) onClick();
              }}
            >
              <Icon icon={icon} size="md" />
            </div>
          )
        )}
        {type === 'password' ? (
          <div
            className="co-field-icon-right d-inline-block ms-2"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <Icon icon={showPassword ? 'hide-pass' : 'show-pass'} />
          </div>
        ) : error ? (
          <div className="co-field-icon-right d-inline-block ms-2">
            <Icon icon="error" />
          </div>
        ) : (
          icon !== '' &&
          iconPos === 'right' && (
            <div
              className="co-field-icon-right d-inline-block ms-2"
              onClick={(e) => {
                if (onClick) onClick();
              }}
            >
              <Icon icon={icon} size={iconSize} />
            </div>
          )
        )}

        {prepend && <div className="co-field-prepend">{prepend}</div>}
        {append && <div className="co-field-append">{append}</div>}

        {searchCandidates.length > 0 && (
          <div className="co-dropdown-list">
            {searchCandidates.map((item, index) => (
              <div
                className="co-dropdown-link"
                key={`search-candidates-${item.id}`}
                onClick={() => handleSearchItem(item)}
              >
                {item.rendered ?? item.label}
              </div>
            ))}
          </div>
        )}
      </div>
      {error && <div className="co-field-error">{_.capitalize(error)}</div>}
    </div>
  );
};

export default forwardRef(Input);
