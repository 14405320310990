import { createStore, applyMiddleware, compose, Dispatch, AnyAction } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import reducers from './reducers';
import ActionTypes from './constants/ActionTypes';
import { REDUX_PERSIST_KEY } from 'configs/config';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['auth'],
  blacklist: ['app'],
};

const jwtChecker = (store: any) => (next: Dispatch<AnyAction>) => (action: any) => {
  let result = next(action);
  if (
    action.type &&
    typeof action.type === 'string' &&
    action.type.includes('FAILURE') &&
    action.payload /*.response*/ &&
    action.payload /*.response.status*/ === 401
  ) {
    result = next({ type: ActionTypes.AUTH_LOGOUT.SUCCESS });
  }
  return result;
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(jwtChecker, ...middlewares)));
const persistor = persistStore(store);

export { store, persistor };
