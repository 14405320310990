import React, { useState, useEffect, ReactNode, FC} from 'react';
import Icon from 'components/Icon';
import { TYPE_SIZE } from 'types';
import Skeleton from 'react-loading-skeleton';

export interface ColumnInfo {
  title: string | undefined;
  show?: TYPE_SIZE;
  align?: 'left' | 'center' | 'right';
  valign?: 'top' | 'center' | 'bottom';
  width?: number | undefined;
  minWidth?: number | undefined;
  mark?: boolean;
  flex?: number | undefined;
  border?: {
    left?: boolean;
    right?: boolean;
    top?: boolean;
    bottom?: boolean;
  };
  skeleton?: ReactNode;
  render: (val: string[] | number[] | any) => ReactNode;
}

interface TableProps {
  className?: string;
  column: ColumnInfo[];
  noHeader?: boolean;
  data: string[][] | number[][] | any[] | undefined;
  page?: number;
  rowsPerPage?: number;
  isLoading?: boolean;
  skeletonRows?: number;
}

const Table : FC<TableProps> = ({
  column,
  data,
  noHeader = false,
  className = '',
  page,
  rowsPerPage,
  isLoading,
  skeletonRows = 2,
}) => {
  const [pagedData, setPagedData] = useState<string[][] | number[][] | any[]>([]);

  useEffect(() => {
    if (isLoading) {
      setPagedData(new Array(skeletonRows).fill(null));
    } else if (data) {
      if (page && rowsPerPage) setPagedData(data.slice((page - 1) * rowsPerPage, page * rowsPerPage));
      else setPagedData(data);
    } else setPagedData([]);
  }, [data, page, rowsPerPage, isLoading, skeletonRows]);

  const getCellClass = (colInfo: ColumnInfo) => {
    const cellAlign = colInfo.align || 'left';
    const cellVAlign = colInfo.valign || 'center';
    let cellClass = colInfo.width !== undefined ? 'flex-0' : 'flex-' + (colInfo.flex || 1);
    cellClass +=
      ' ' +
      (cellAlign === 'center'
        ? 'justify-content-center text-center'
        : cellAlign === 'right'
        ? 'justify-content-end text-right'
        : 'justify-content-start');
    cellClass +=
      ' ' +
      (cellVAlign === 'center'
        ? 'align-items-center'
        : cellVAlign === 'bottom'
        ? 'align-items-end'
        : 'align-items-start');
    cellClass += ' ' + (colInfo.border?.left ? 'border-left' : '');
    cellClass += ' ' + (colInfo.border?.right ? 'border-right' : '');
    cellClass += ' ' + (colInfo.border?.top ? 'border-top' : '');
    cellClass += ' ' + (colInfo.border?.bottom ? 'border-bottom' : '');

    return cellClass;
  };

  const getCellStyle = (colInfo: ColumnInfo) => {
    let style = {};
    if ((colInfo.width || -1) >= 0) style = { width: colInfo.width + 'px' };
    if (colInfo.minWidth) style = { ...style, minWidth: colInfo.minWidth + 'px' };
    return style;
  };

  return (
    <div className={`co-table ${className}`}>
      <div className="co-scroll-wrapper-x">
        <div className="co-table-row-wrapper flex-1">
        {noHeader === false && (
          <div className="co-table-row-header d-flex align-items-stretch align-self-stretch">
            {column.map((colInfo: ColumnInfo, idx) => (
              <div
                className={`co-table-header-cell d-flex ${getCellClass(colInfo)}`}
                key={`co-table-header-col-${idx}`}
                style={getCellStyle(colInfo)}
              >
                {colInfo.title}
                {colInfo.mark && (
                  <span className="ms-1 pointer">
                    <Icon icon="info" />
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
        
          {pagedData.map((row, rowIdx) => (
            <div className="co-table-row d-flex align-items-stretch" key={`co-table-row-${rowIdx}`}>
              {column.map((colInfo: ColumnInfo, colIdx: number) => {
                return (
                  <div
                    className={`co-table-cell d-flex ${getCellClass(colInfo)}`}
                    key={`co-table-col-${colIdx}`}
                    style={getCellStyle(colInfo)}
                  >
                    {isLoading || row === null ? (
                      <div className="w-100">
                        {colInfo?.skeleton ? colInfo?.skeleton : <Skeleton />}
                      </div>
                    ) : (
                      colInfo.render(row)
                    )}
                  </div>
                );
              })}
            </div>
          ))}
          {!isLoading && pagedData.length === 0 && (
            <div className="co-table-row empty d-flex align-items-center justify-content-center">
              <div className="text-center">
                {/* <div
                  className="d-flex align-items-center justify-content-center rounded-circle bg-light-gray mx-auto"
                  style={{ width: 52, height: 52 }}
                >
                  <Icon icon="empty" />
                </div> */}
                <div className="text-medium my-4">No Data Available</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
