import { ICompanyInfo, IDashboardResponse, IUser } from 'types';
import BaseApi from './BaseApi';
import { useMutation, useQuery } from 'react-query';

class AuthApi extends BaseApi {
  loginUser = ({ email, password }: { email: string; password: string }) => {
    return this.instance.post('/auth/login', { email, password }).then((resp) => resp.data);
  };

  signupUser = ({
    firstName,
    lastName,
    email,
    password,
    country
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    country: string;
  }) => {
    return this.instance.post('/auth/register/user', { firstName, lastName, email, password, country });
  };

  signupCompany = ({ name, email, password }: { name: string; email: string; password: string }) => {
    return this.instance.post('/auth/register/company', { name, email, password });
  };

  confirmAccount = (token: string) => {
    return this.instance.post(`/auth/confirm/${token}`).then((resp) => resp.data);
  };

  forgetPassword = (email: string) => {
    return this.instance.post('/auth/forget-password', { email }).then((resp) => resp.data);
  };

  resetPassword = ({ token, password }: { token?: string; password: string }) => {
    return this.instance.post('/auth/reset-password', { token, password }).then((resp) => resp.data);
  };

  getProfile = () => {
    return this.instance
      .get('/profile')
      .then((resp) => resp.data as { user: IUser | null; company: ICompanyInfo | null });
  };

  getDashboard = (from?: number, to?: number) => {
    return this.instance
      .get('/dashboard', {
        params: {
          from,
          to,
        },
      })
      .then((resp) => resp.data as IDashboardResponse);
  };
  
  forceLogout = () => {
    return this.instance.post('/auth/force-logout').then((resp) => resp.data);
  };
}

const api = new AuthApi();

export const useGetProfile = (enabled: boolean) => {
  return useQuery('profile', () => api.getProfile(), {
    enabled: enabled
  });
};

export const useGetDashboard = (from?: number, to?: number) => {
  return useQuery(['dashboard', from, to], () => api.getDashboard(from, to));
};

export const useLoginUser = () => {
  return useMutation(api.loginUser);
};

export const useSignupUser = () => {
  return useMutation(api.signupUser);
};

export const useSignupCompany = () => {
  return useMutation(api.signupCompany);
};

export const useConfirmAccount = () => {
  return useMutation(api.confirmAccount);
};

export const useForgetPassword = () => {
  return useMutation(api.forgetPassword);
};

export const useResetPassword = () => {
  return useMutation(api.resetPassword);
};

export const useForceLogout = () => {
  return useMutation(api.forceLogout);
};

export default api;
