import React, { useState, useMemo, useCallback } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'hooks';
import Avatar from 'components/Avatar';
import { Dropdown } from 'components/Base';
import Icon from 'components/Icon';
import ActionTypes from 'store/constants/ActionTypes';
import { APP_PREFIX_PATH, DASHBOARD_PREFIX_PATH } from 'configs/config';
import NotificationBadge from './notification';

import { ReactComponent as ImgLogo } from 'assets/images/logo.svg';

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentCompany } = useAppSelector((state) => state.auth);
  
  const renderMenu = useCallback(
    () => (
      <div className="d-flex align-items-center ms-4">
        <Avatar size="sm" color="yellow" name={currentCompany?.name} src={currentCompany?.logoUrl} />
        <div className="font-inter text-title text-small text-semibold ms-2">{currentCompany?.name}</div>
      </div>
    ),
    [currentCompany]
  );

  const renderProfileMenu = useCallback(() => {
    const onClickSignOut = () => {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT.SUCCESS,
      });
      navigate('/');
    };

    const onClickProfile = () => {
      navigate(`${DASHBOARD_PREFIX_PATH}/profile`);
    }

    return (
      <Dropdown button={renderMenu} popupPos="bottom-end" hideToggle>
        <div className="d-flex flex-column gap-3 px-3 py-2">
          <div className="co-dropdown-link" onClick={onClickProfile}>
            <Icon icon="menu-profile" size='md' />
            <span className="text-bold ms-2">Profile</span>
          </div>
          <div className="divider" />
          <div className="co-dropdown-link" onClick={onClickSignOut}>
            <Icon icon="arrow-left" size='md' />
            <span className="text-bold ms-2">Sign Out</span>
          </div>
        </div>
      </Dropdown>
    );
  }, [renderMenu, dispatch, navigate]);

  return (
    <div className="co-header">
      <div className="container">
        <Navbar className="navbar-default">
          <div className="navbar-header w-100 d-flex align-items-center justify-content-between">
            <Navbar.Brand href={`${APP_PREFIX_PATH}/`} className="co-brand">
              <ImgLogo />
            </Navbar.Brand>

            <div className="d-flex align-items-center ms-auto">
              <NavLink to={`${DASHBOARD_PREFIX_PATH}/notification`}>
                <NotificationBadge />
              </NavLink>
              {renderProfileMenu()}
            </div>
          </div>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
